import moment from 'moment';

export function generateMonthRange(
  firstDate: moment.Moment,
  lastDate: moment.Moment
): ReadonlyArray<moment.Moment> {
  /*
  ENHANCEMENT: this doesn't give a reverse range if back to front dates are entered
  (might be nice as a future expansion but not needed yet)
  */
  const startOfFirstMonth = firstDate.clone().startOf('month');
  const startOfLastMonth = lastDate.clone().startOf('month');
  return startOfFirstMonth.isSameOrAfter(startOfLastMonth)
    ? [startOfFirstMonth]
    : [
        startOfFirstMonth,
        ...generateMonthRange(
          startOfFirstMonth.clone().add(1, 'months'),
          startOfLastMonth
        ),
      ];
}
