import * as React from 'react';
import { toast } from 'react-toastify';
import { AnyAction, Dispatch } from 'redux';

import ToastInner from '^/common/toast-inner';
import { getResponseFromAction, isServerError } from '^/common/utils/responses';

export function hasServerError(action: AnyAction): boolean {
  const response = getResponseFromAction(action);
  return response ? isServerError(response) : false;
}

export default () => (next: Dispatch) => (action: AnyAction) => {
  if (hasServerError(action)) {
    toast.error(() => <ToastInner message="Something went wrong." isError />, {
      closeButton: false,
    });
  }

  return next(action);
};
