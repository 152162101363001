// NOTE: Donot delete commented codes
import { Container, ContentBox } from '@dabapps/roe';
import { Dropdown, Layout, Menu, Spin, Typography, Button, Tree } from 'antd';
import {
  ClusterOutlined,
  BookOutlined,
  SyncOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import { DataNode } from 'antd/lib/tree';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import ClusterApis from '^/api-services/cluster/cluster.service';
import { IClusterDetails } from '^/api-services/cluster/types';
import { toast } from 'react-toastify';
import './cluster.styles.less';
import { CLUSTER_ADMIN_LABELS } from '^/common/labels-english';
import { ITreeNode, ITreeNodeTypes } from './types';
import AddClusterModal from './addClusterModal';
import { without } from 'underscore';
import SchoolModal from './schoolModal';
import { ConfirmDeleteCluster } from './confirmDeleteCluster';

interface IRouteParamProps {
  clusterId: string;
}
type Props = RouteComponentProps<IRouteParamProps>;
const { Title } = Typography;

interface ISelectedClusterID {
  type: ITreeNodeTypes;
  clusterNodeKey: string;
  children: ITreeNode[] | undefined;
}

export function ClusterTreePage(props: Props) {
  const { match } = props;
  const rootClusterID = match.params?.clusterId;
  const [treeData, setTreeData] = useState<ITreeNode[]>([]);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  // const [loadedKeys, setLoadedKeys] = useState<string[]>([]);
  const [spinner, setSpinner] = useState(true);
  const [selectedData, setSelectedData] = useState<ISelectedClusterID | null>(
    null
  );

  const mapChildren = (data: IClusterDetails) => {
    const children: ITreeNode[] = [];
    const parent = data.parent;
    for (const ele of data.children) {
      children.push({
        key: `${ele.id}`,
        title: ele.name,
        nodeType: ITreeNodeTypes.CLUSTER,
        currentNodeKey: ele.id,
        isLeaf: ele.is_leaf && ele.num_schools === 0,
      });
    }
    for (const ele of data.schools) {
      children.push({
        key: `${parent}***${ele.id}`,
        currentNodeKey: ele.id,
        title: ele.name,
        isLeaf: true,
        nodeType: ITreeNodeTypes.SCHOOL,
      });
    }
    return children;
  };

  const loadRootNode = () => {
    if (rootClusterID) {
      setSpinner(true);
      ClusterApis.getClusterDetails(rootClusterID)
        .then((data) => {
          const children = mapChildren(data);
          const rootNode: ITreeNode = {
            key: rootClusterID,
            title: data.name,
            nodeType: ITreeNodeTypes.CLUSTER,
            isLeaf:
              data.children.length === 0 && data.schools.length === 0
                ? true
                : false,
            children,
            currentNodeKey: rootClusterID,
          };
          setTreeData([rootNode]);
          setExpandedKeys([rootClusterID]);
          // setLoadedKeys([rootClusterID]);
          setSpinner(false);
        })
        .catch(() => {
          toast.error(CLUSTER_ADMIN_LABELS.error);
          setSpinner(false);
        });
    }
  };

  useEffect(() => {
    loadRootNode();
  }, [rootClusterID]);

  const updateTreeData = (
    list: ITreeNode[],
    key: React.Key,
    children: ITreeNode[],
    extraDetails: ITreeNode | null = null
  ): ITreeNode[] => {
    return list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          ...extraDetails,
          children,
        };
      } else if (node.children?.length) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children, extraDetails),
        };
      }
      return node;
    });
  };

  const onLoadData = (
    key: string,
    children: DataNode[] | undefined
  ): Promise<void> => {
    return new Promise((resolve, reject) => {
      if (children?.length) {
        resolve();
        return;
      }
      ClusterApis.getClusterDetails(key as string)
        .then((details) => {
          const children = mapChildren(details);
          const nodeDetails: ITreeNode = {
            title: details.name,
            key: `${details.id}`,
            currentNodeKey: details.id,
            nodeType: ITreeNodeTypes.CLUSTER,
            isLeaf:
              details.children.length === 0 && details.schools.length === 0,
          };
          // const indexOfLoadedData = loadedKeys.findIndex(
          //   (loadedKey) => loadedKey === key
          // );
          // if (indexOfLoadedData === -1) setLoadedKeys((keys) => [...keys, key]);
          setTreeData((origin) =>
            updateTreeData(origin, key, children, nodeDetails)
          );
          resolve();
        })
        .catch(() => {
          reject();
          toast.error(CLUSTER_ADMIN_LABELS.error);
        });
    });
  };

  // const Image = React.memo(function Image({ src }: any) {
  //   return <img src={src} className="spinner" />;
  // });

  const TitleRender = ({
    nodeType,
    title,
    currentNodeKey,
    children,
  }: ITreeNode) => {
    const { menuOptions } = CLUSTER_ADMIN_LABELS;
    if (nodeType === ITreeNodeTypes.SCHOOL)
      return (
        <span className="node-icon-title">
          <BookOutlined className="node-icon" />
          {/* <Image src="/static/cluster-school.png" /> */}
          {title}
        </span>
      );
    return (
      <span className="node-icon-title">
        <ClusterOutlined className="node-icon" />
        {/* <Image src="/static/cluster.png" /> */}
        {title}
        <Dropdown
          trigger={['hover']}
          overlay={() => (
            <Menu
              onClick={({ key }) => {
                setSelectedData({
                  children: children,
                  clusterNodeKey: currentNodeKey,
                  type: key as never,
                });
              }}
            >
              <Menu.Item key={ITreeNodeTypes.CLUSTER}>
                {menuOptions.add}
              </Menu.Item>
              <Menu.Item key={ITreeNodeTypes.EDIT_CLUSTER}>
                {menuOptions.edit}
              </Menu.Item>
              <Menu.Item key={ITreeNodeTypes.DELETE_CLUSTER}>
                {menuOptions.delete}
              </Menu.Item>
              <Menu.Item key={ITreeNodeTypes.SCHOOL}>
                {menuOptions.school}
              </Menu.Item>
            </Menu>
          )}
        >
          <Button type="link" size="small">
            <div className="dot-container">
              <div className="dot" />
              <div className="dot" />
              <div className="dot" />
            </div>
          </Button>
        </Dropdown>
      </span>
    );
  };

  const getAllKeys = (key: string, childrenKeys: string[] = []) => {
    childrenKeys.push(key);
    const children = treeData.find((ele) => ele.currentNodeKey === key)
      ?.children;
    if (children?.length)
      for (let i = 0; i < children.length; i++) {
        getAllKeys(children[i].key, childrenKeys);
      }
  };

  const reset = () => {
    // to load the complete page
    setTreeData([]);
    setExpandedKeys([]);
    // setLoadedKeys([]);
    loadRootNode();
  };

  const onModalClose = () => {
    if (selectedData) {
      // delete scenario
      if (selectedData.type === ITreeNodeTypes.DELETE_CLUSTER) {
        if (selectedData.clusterNodeKey === rootClusterID) {
          props.history.goBack();
        } else reset();
        setSelectedData(null);
        return;
      }
      const keys: string[] = [];
      getAllKeys(selectedData.clusterNodeKey, keys); // using pass by ref to save memory
      keys.splice(0, 1);
      // const newLoadedKeys = without([...loadedKeys], ...keys);
      const newExpandedKeys = without(expandedKeys, ...keys);
      setExpandedKeys(newExpandedKeys);
      // setLoadedKeys(newLoadedKeys);
      onLoadData(selectedData.clusterNodeKey, []);
      setSelectedData(null);
    }
  };

  return (
    <Layout className="cluster-management-tree">
      <Container style={{ minHeight: '60vh' }}>
        {treeData?.length === 0 ? (
          <div className="spin-container">
            <Spin />
          </div>
        ) : (
          <>
            <div className="row-center">
              <Title level={3}>{treeData[0].title}</Title>
              <Button
                className="reload-btn"
                type="link"
                onClick={reset}
                loading={spinner}
                icon={<SyncOutlined />}
              >
                {CLUSTER_ADMIN_LABELS.reload}
              </Button>
            </div>
            <ContentBox>
              <Tree
                switcherIcon={
                  <CaretDownOutlined style={{ fontSize: '12px' }} />
                }
                showLine={{ showLeafIcon: false }}
                loadData={({ key, children }) =>
                  onLoadData(key as string, children)
                }
                expandedKeys={expandedKeys}
                treeData={treeData}
                selectable={false}
                titleRender={(data) => <TitleRender {...(data as ITreeNode)} />}
                onExpand={(keys) => setExpandedKeys(keys)}
                loadedKeys={[]} // this makes auto fetch for grand child nodes
              />
            </ContentBox>
          </>
        )}
      </Container>
      {selectedData ? (
        selectedData.type === ITreeNodeTypes.SCHOOL ? (
          <SchoolModal
            closeModal={onModalClose}
            clusterID={selectedData.clusterNodeKey}
          />
        ) : selectedData.type === ITreeNodeTypes.DELETE_CLUSTER ? (
          <ConfirmDeleteCluster
            onCancel={onModalClose}
            clusterID={selectedData.clusterNodeKey}
          />
        ) : (
          // if clusterID is there then edit mode else add mode
          <AddClusterModal
            closeModal={onModalClose}
            parentTreeID={
              selectedData.type === ITreeNodeTypes.CLUSTER // add sub cluster
                ? selectedData.clusterNodeKey
                : null
            }
            clusterID={
              selectedData.type === ITreeNodeTypes.CLUSTER // add sub cluster
                ? null
                : selectedData.clusterNodeKey // for edit sub cluster
            }
            clusterManager={null}
          />
        )
      ) : null}
    </Layout>
  );
}
