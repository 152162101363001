import React, { useEffect, useState } from 'react';
import './cluster.styles.less';
import { Typography, Button, Layout, Table, Pagination, Space } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { Container, ContentBox } from '@dabapps/roe';
import AddClusterModal from './addClusterModal';
import { DATE_RANGE_FORMAT, PAGE_SIZE } from '^/common/constants';
import ClusterApis from '^/api-services/cluster/cluster.service';
import { ColumnsType } from 'antd/lib/table/interface';
import { IRootClusterDetails } from '^/api-services/cluster/types';
import moment from 'antd/node_modules/moment';
import useAPI from '^/api-services/useApi';
import { CLUSTER_ADMIN_LABELS } from '^/common/labels-english';
import { RouteConstants } from '^/common/route-constants';
import { RouteComponentProps } from 'react-router-dom';
import { ITreeNodeTypes } from './types';
import { isNull } from 'underscore';
import { ConfirmDeleteCluster } from './confirmDeleteCluster';

const { Title } = Typography;
const { Header } = Layout;
interface RouteParamProps {
  clusterId: string;
  history: RouteComponentProps['history'];
}

type Props = RouteParamProps;
export const ClusterMainPage = (props: Props): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState<ITreeNodeTypes | null>(null);
  const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: PAGE_SIZE,
  });
  const {
    callAPI: fetchRootClusters,
    response: rootClusters,
    loading: rootClusterLoading,
  } = useAPI(ClusterApis.getRootClusters);

  const loading = rootClusterLoading;

  useEffect(() => {
    isNull(isModalOpen) && fetchRootClusters(pageInfo);
  }, [pageInfo, isModalOpen]);

  const [clusterID, setClusterId] = useState('');

  const changePage = (page: number, pageSize?: number) => {
    setPageInfo({
      page: page,
      pageSize: pageSize ? pageSize : pageInfo.pageSize,
    });
  };

  const onModalClose = () => {
    setIsModalOpen(null);
    setClusterId('');
  };

  const onOperationClick = (id: string, type: ITreeNodeTypes) => {
    setClusterId(id);
    setIsModalOpen(type);
  };

  const tableColumns: ColumnsType<IRootClusterDetails> = [
    {
      title: CLUSTER_ADMIN_LABELS.name,
      dataIndex: 'name',
    },
    {
      title: CLUSTER_ADMIN_LABELS.schoolCount,
      dataIndex: 'num_schools',
    },
    {
      title: CLUSTER_ADMIN_LABELS.modifiedBy,
      dataIndex: 'last_modified_by',
      render: (value) =>
        value.first_name
          ? `${value.first_name} ${value.last_name}`
          : value.email,
    },
    {
      title: CLUSTER_ADMIN_LABELS.modifiedDate,
      dataIndex: 'modified_at',
      render: (date) => moment(date).format(DATE_RANGE_FORMAT),
    },
    {
      title: '',
      dataIndex: 'id',
      render: function ActionButtons(id: string) {
        return (
          <Space>
            <Button
              icon={<EyeOutlined />}
              shape="circle"
              title={CLUSTER_ADMIN_LABELS.columns.view}
              onClick={() =>
                props.history.push({
                  pathname: `${RouteConstants.cluster.route}/${id}`,
                })
              }
            ></Button>
            <Button
              onClick={() => onOperationClick(id, ITreeNodeTypes.EDIT_CLUSTER)}
              title={CLUSTER_ADMIN_LABELS.columns.edit}
              icon={<EditOutlined />}
              shape="circle"
            ></Button>
            {/* <Popconfirm
            title={CLUSTER_ADMIN_LABELS.areYouSure}
            onConfirm={() => onDeleteCluster(id)}
            okText={CLUSTER_ADMIN_LABELS.yes}
            cancelText={CLUSTER_ADMIN_LABELS.no}
            overlayClassName="cluster-popover"
          > */}
            <Button
              title={CLUSTER_ADMIN_LABELS.columns.delete}
              icon={<DeleteOutlined />}
              shape="circle"
              onClick={() =>
                onOperationClick(id, ITreeNodeTypes.DELETE_CLUSTER)
              }
            ></Button>
            {/* </Popconfirm> */}
          </Space>
        );
      },
    },
  ];

  return (
    <Layout className="cluster-management-container">
      <Header className="title-container">
        <Title level={3}>{CLUSTER_ADMIN_LABELS.rootTableTitle}</Title>
        <Button
          type="primary"
          shape="round"
          onClick={() => setIsModalOpen(ITreeNodeTypes.CLUSTER)}
        >
          {CLUSTER_ADMIN_LABELS.newClusterBtn}
        </Button>
      </Header>
      <Container>
        <ContentBox>
          <Pagination
            current={pageInfo.page}
            total={rootClusters?.count}
            pageSize={pageInfo.pageSize}
            onChange={changePage}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            showSizeChanger
            disabled={loading}
          />
          <Table
            rowKey="id"
            columns={tableColumns}
            dataSource={rootClusters?.results || []}
            pagination={false}
            loading={loading}
          />
        </ContentBox>
      </Container>

      {/* if clusterID is there then edit mode else add mode */}
      {isModalOpen ? (
        isModalOpen === ITreeNodeTypes.CLUSTER ||
        isModalOpen === ITreeNodeTypes.EDIT_CLUSTER ? (
          <AddClusterModal
            closeModal={onModalClose}
            parentTreeID={null}
            clusterID={clusterID}
            clusterManager={null}
          />
        ) : (
          <ConfirmDeleteCluster onCancel={onModalClose} clusterID={clusterID} />
        )
      ) : null}
    </Layout>
  );
};
