import { TOGGLE_SHOW_SCHOOL_SWITCHER_DROPDOWN } from '^/app/authenticated/school-switcher/actions';
import { SET_CURRENT_SCHOOL } from '^/common/schools/actions';
import { AnyAction } from 'redux';

export function isSchoolSwitcherVisible(
  state: boolean = false,
  action: AnyAction
) {
  switch (action.type) {
    case TOGGLE_SHOW_SCHOOL_SWITCHER_DROPDOWN:
      return !state;
    case SET_CURRENT_SCHOOL:
      return false;
    default:
      return state;
  }
}
