import {
  GET_COLLECTION,
  getCollectionResultsByName,
} from '@dabapps/redux-api-collections';
import {
  GET_ITEM,
  getItemByName,
} from '@dabapps/redux-api-collections/dist/items';
import { isPending } from '@dabapps/redux-requests';
import { Container, SpacedGroup } from '@dabapps/roe';
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import _ from 'underscore';

import { toggleShowSchoolSwitcherDropdown } from '^/app/authenticated/school-switcher/actions';
import { BasicSchool } from '^/app/authenticated/school-switcher/types';
import {
  isSingularSchoolOption,
  setCurrentSchoolAndReload,
} from '^/app/authenticated/school-switcher/utils';
import { LoggedInUser } from '^/common/authentication/types';
import { collections } from '^/common/collections';
import LoadingSpinner from '^/common/loading-spinner';
import { setCurrentSchool } from '^/common/schools/actions';
import { CurrentSchool } from '^/common/schools/reducers';
import { loggedInUserIsAdmin } from '^/common/utils/roles';
import { StoreState } from '^/store/types';

const {
  actions: { getCollection, getItem },
} = collections;

interface CurrentSchoolProps {
  currentSchool?: BasicSchool;
  onClickChange: () => void;
}

const CurrentSchool = (props: CurrentSchoolProps) => {
  return props.currentSchool ? (
    <div className="display-inline-block padding-top-small padding-bottom-small">
      <span className="font-weight-light font-color-grey margin-right-small">
        Viewing School:
      </span>
      {` ${props.currentSchool.name}, ${props.currentSchool.location} `}
      <a onClick={props.onClickChange} className="font-size-small">
        Change
      </a>
    </div>
  ) : null;
};

interface SchoolOption {
  value: string;
  label: string;
  sso: string;
}

interface StateProps {
  loggedInUser: LoggedInUser | null;
  schools: ReadonlyArray<BasicSchool>;
  currentSchool: CurrentSchool;
  currentSchoolData: BasicSchool;
  isSchoolSwitcherVisible: boolean;
  schoolListRequestPending: boolean;
  schoolDetailRequestPending: boolean;
}

interface DispatchProps {
  setCurrentSchool: typeof setCurrentSchool;
  toggleShowSchoolSwitcherDropdown: typeof toggleShowSchoolSwitcherDropdown;
  push: typeof push;
  getCollection: typeof getCollection;
  getItem: typeof getItem;
  setSelectedSchool: any;
}

type Props = StateProps & DispatchProps;

const SCHOOL_SEARCH_DEBOUNCE_WAIT_MS = 300;

export class SchoolSwitcher extends React.PureComponent<Props> {
  constructor(props: Props) {
    super(props);
    this.handleInputChange = _.debounce(
      this.handleInputChange,
      SCHOOL_SEARCH_DEBOUNCE_WAIT_MS
    );
  }

  public componentDidMount() {
    this.searchSchools('');
    if (this.props.currentSchool) {
      this.loadSelectedSchool(this.props.currentSchool);
    }
  }

  public render() {
    const { schoolListRequestPending } = this.props;

    return (
      <Select
        className="school-switcher-select"
        classNamePrefix="school-switcher"
        options={this.transformSchoolsListToSelectOptions(this.props.schools)}
        isLoading={schoolListRequestPending}
        onChange={this.onSelectChange}
        onInputChange={this.handleInputChange}
        autoFocus
        onBlur={this.onBlurSelect}
      />
    );
  }

  private setSchool = (schoolId: string) => {
    // setCurrentSchoolAndReload(schoolId);
  };

  private loadSelectedSchool = (schoolId: string) => {
    this.props.getItem('schools/schools-list', schoolId);
  };

  private handleInputChange = (query: string) => {
    this.searchSchools(query);
  };

  private searchSchools = (query: string) => {
    this.props.getCollection(
      'schools/schools-list',
      {
        search: query,
        page: 1,
        pageSize: 10,
      },
      'schools/schools-list'
    );
  };

  private redirectToSchools = () => {
    this.props.push('/schools/');
  };

  private transformSchoolsListToSelectOptions = (
    schools: ReadonlyArray<BasicSchool>
  ) => {
    return schools.map((school) => {
      return {
        value: school.id,
        label: `${school.name}`,
        sso: school.sso,
      };
    });
  };

  private onSelectChange = (selectedSchool: ValueType<SchoolOption>) => {
    if (selectedSchool && isSingularSchoolOption(selectedSchool)) {
      console.log('on change', selectedSchool);
      this.setSchool(selectedSchool.value);
      this.props.setSelectedSchool(selectedSchool);
    }
  };

  private onBlurSelect = () => {
    this.props.toggleShowSchoolSwitcherDropdown();
  };
}

function mapStateToProps({
  loggedInUser,
  currentSchool,
  isSchoolSwitcherVisible,
  collections: collectionsState,
  items,
  responses,
}: StoreState): StateProps {
  return {
    loggedInUser,
    // FIXME: fix types once collctions lib is updated
    schools: getCollectionResultsByName(
      collectionsState,
      'schools/schools-list',
      'schools/schools-list'
    ) as ReadonlyArray<BasicSchool>,
    schoolListRequestPending: isPending(
      responses,
      GET_COLLECTION,
      'schools/schools-list'
    ),
    schoolDetailRequestPending: isPending(
      responses,
      GET_ITEM,
      'schools/schools-list'
    ),
    currentSchoolData: getItemByName(
      items,
      'schools/schools-list'
    ) as BasicSchool,
    currentSchool,
    isSchoolSwitcherVisible,
  };
}

export default connect(mapStateToProps, {
  getCollection,
  setCurrentSchool,
  toggleShowSchoolSwitcherDropdown,
  push,
  getItem,
})(SchoolSwitcher);
