import React, { useEffect, useState, useMemo } from 'react';
import './school.styles.less';
import { connect } from 'react-redux';
import { Pagination, Table, Layout } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ISchool } from '^/api-services/schools/types';
import { PAGE_SIZE, SEARCH_FIELD_MAXLENGTH } from '^/common/constants';
import {
  GLOBAL_DASHBOARD_LABELS,
  SCHOOLS_LABELS,
} from '^/common/labels-english';
import { StoreState } from '^/store/types';
import { Column, Container, ContentBox, Row } from '@dabapps/roe';
import { IGlobalDashboardSchool } from '^/api-services/dashboard/types';
import { SorterResult } from 'antd/lib/table/interface';
import { BasicSearchField } from '^/app/components';

interface StateProps {
  currentSchool: string | null;
}

interface SchoolProps {
  data: IGlobalDashboardSchool[];
}

type Props = StateProps & SchoolProps;

const SchoolList: React.FC<Props> = (props: Props) => {
  const { currentSchool, data } = props;

  const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: PAGE_SIZE,
  });
  const [refresh, setRefresh] = useState(false);
  const [localData, setLocalData] = useState<IGlobalDashboardSchool[]>([]);

  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    if (currentSchool || refresh) {
      setRefresh(false);
    }
  }, [pageInfo, refresh]);

  useEffect(() => {
    if (data) {
      setLocalData(data);
    }
  }, [data]);

  const changePage = (page: number, pageSize?: number) => {
    setPageInfo({
      page: page,
      pageSize: pageSize ? pageSize : pageInfo.pageSize,
    });
  };

  const columnLabel = GLOBAL_DASHBOARD_LABELS.column;
  const getColumns = useMemo(() => {
    const columns: ColumnsType<ISchool> = [
      {
        title: columnLabel.schoolName,
        dataIndex: 'name',
      },
      {
        title: columnLabel.userCount,
        dataIndex: 'user_count',
        sorter: true,
      },
      {
        title: columnLabel.wau,
        dataIndex: 'wau',
        sorter: true,
      },
      {
        title: columnLabel.mau,
        dataIndex: 'mau',
        sorter: true,
      },
      {
        title: columnLabel.noactivitieslogged,
        dataIndex: 'noactivitieslogged',
        sorter: true,
      },
      {
        title: columnLabel.neverloggedin,
        dataIndex: 'neverloggedin',
        sorter: true,
      },
    ];
    return columns;
  }, []);

  const { page, pageSize } = pageInfo;

  const ascendCompare = (a: number, b: number) => {
    return a - b;
  };

  const descendCompare = (a: number, b: number) => {
    return b - a;
  };

  const filteredData = localData?.filter(
    (s: IGlobalDashboardSchool) =>
      !searchQuery || s.name.toLowerCase().indexOf(searchQuery) >= 0
  );
  const paginatedData =
    filteredData.slice((page - 1) * pageSize, page * pageSize) || [];

  return (
    <Layout className="school-management-container">
      <Container>
        <ContentBox>
          <Row>
            <Column sm={12} md={9}>
              <Pagination
                current={pageInfo.page}
                total={filteredData.length}
                pageSize={pageInfo.pageSize}
                onChange={changePage}
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`
                }
                showSizeChanger
              />
            </Column>
            <Column sm={12} md={3}>
              <BasicSearchField
                className="user-search-input search"
                placeholder={SCHOOLS_LABELS.searchSchools}
                maxLength={SEARCH_FIELD_MAXLENGTH}
                value={searchQuery}
                onChange={(e) =>
                  setSearchQuery(
                    e.target.value
                      ? e.target.value.toLowerCase()
                      : e.target.value
                  )
                }
                allowClear={true}
                size="small"
                style={{ width: '16em' }}
              />
            </Column>
          </Row>
          <Table
            rowKey="id"
            columns={getColumns}
            dataSource={paginatedData}
            pagination={false}
            onChange={(pagination, filters, sorter, extra) => {
              console.log(pagination, filters, 'sorter>>>>', sorter, extra);
              const { field, order } = sorter as SorterResult<ISchool>;
              const { action } = extra;

              if (action && action === 'sort') {
                const comparator =
                  order === 'ascend' ? ascendCompare : descendCompare;
                const mutableData = [...localData];
                mutableData.sort(
                  (a: IGlobalDashboardSchool, b: IGlobalDashboardSchool) =>
                    comparator(a[field] as number, b[field] as number)
                );
                setLocalData(mutableData);
              }
            }}
          />
        </ContentBox>
      </Container>
    </Layout>
  );
};

export function mapStateToProps(state: StoreState): StateProps {
  return {
    currentSchool: state.currentSchool,
  };
}

export default connect(mapStateToProps)(SchoolList);
