import { CLOSE_MODAL, OPEN_MODAL } from '^/common/modals/actions';
import { AnyAction } from 'redux';

export function modals(
  state: ReadonlyArray<React.ReactNode> = [],
  action: AnyAction
): ReadonlyArray<React.ReactNode> {
  switch (action.type) {
    case OPEN_MODAL: {
      return [action.payload];
    }
    case CLOSE_MODAL: {
      return [];
    }
    default:
      return state;
  }
}
