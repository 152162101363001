import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { StoreState } from '^/store/types';
import { ActivityHighlights } from './highlights/activity-highlights';
import { GraphDashboardProps } from './types';
import './activity-dashboard-styles.less';
import useAPI from '^/api-services/useApi';
import DashboardApi from '^/api-services/dashboard/dashboard.service';
import {
  loggedInUserIsClusterAdmin,
  loggedInUserIsClusterManager,
} from '^/common/utils/roles';

import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';
import {
  IGlobalDashboardHeaderResponse,
  IGlobalDashboardSchool,
} from '^/api-services/dashboard/types';
import SchoolList from './schoolList';
import LoadingSpinner from '^/common/loading-spinner';

const GlobalDashboard: React.FC<GraphDashboardProps> = (
  props: GraphDashboardProps
) => {
  const { currentSchool, loggedInUser } = props;

  const [selectedSchool, setSelectedSchool] = useState(currentSchool);

  useEffect(() => {
    setSelectedSchool(currentSchool);
  }, [currentSchool]);

  const { callAPI: fetchGlobalDashboardData, ...dashboardData } = useAPI<
    any,
    IGlobalDashboardSchool[]
  >(DashboardApi.fetchGlobalDashboardData);

  const {
    callAPI: fetchGlobalDashboardHeaderData,
    ...dashboardHeaderData
  } = useAPI<any, IGlobalDashboardHeaderResponse>(
    DashboardApi.fetchGlobalDashboardHeaderData
  );

  useEffect(() => {
    if (selectedSchool) {
      fetchGlobalDashboardData({});
      fetchGlobalDashboardHeaderData({});
    }
  }, [selectedSchool]);

  if (
    (loggedInUserIsClusterAdmin(loggedInUser) ||
      loggedInUserIsClusterManager(loggedInUser)) &&
    !currentSchool
  ) {
    return (
      <div className="text-align-center">
        <h2>{DASHBOARD_PAGE_LABELS.no_school_assigned_description}</h2>
      </div>
    );
  }

  return (
    <div className="activity-dashboard-container">
      <div className="wrapper mx-auto">
        {dashboardHeaderData.response && (
          <ActivityHighlights data={dashboardHeaderData.response} />
        )}

        {dashboardData.loading ? (
          <div className="wh-100 d-flex justify-content-between align-items-center">
            <LoadingSpinner />
          </div>
        ) : dashboardData.error ? (
          <p className="error">{DASHBOARD_PAGE_LABELS.failed_to_load}</p>
        ) : (
          dashboardData.response && (
            <SchoolList data={dashboardData?.response || []} />
          )
        )}
      </div>
    </div>
  );
};

export function mapStateToProps({
  loggedInUser,
  currentSchool,
}: StoreState): GraphDashboardProps {
  return {
    currentSchool,
    loggedInUser,
  };
}

export default connect(mapStateToProps)(GlobalDashboard);
