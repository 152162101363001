import { AxiosResponse } from 'axios';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';

import { getResponseFromAction } from '^/common/utils/responses';
import { StoreState } from '^/store/types';

function isAuthenticatedInState(state: StoreState): boolean {
  return Boolean(state.loggedInUser);
}

function isUnauthenticatedResponse(response: AxiosResponse | null) {
  return response ? Boolean(response.status === 401) : false;
}

export function shouldLogout(action: AnyAction, state: StoreState): boolean {
  return (
    isUnauthenticatedResponse(getResponseFromAction(action)) &&
    isAuthenticatedInState(state)
  );
}

export default (store: MiddlewareAPI<Dispatch<AnyAction>, StoreState>) => (
  next: Dispatch
) => (action: AnyAction) => {
  if (shouldLogout(action, store.getState())) {
    return window.location.replace('/');
  }

  return next(action);
};
