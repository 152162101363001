import { AnyAction } from 'redux';

import { GET_LOGGED_IN_USER, LOGOUT } from '^/common/authentication/actions';
import { SET_CURRENT_SCHOOL } from '^/common/schools/actions';

export type CurrentSchool = string | null;

export function currentSchool(state: CurrentSchool = null, action: AnyAction) {
  switch (action.type) {
    case SET_CURRENT_SCHOOL:
      return action.payload;

    case GET_LOGGED_IN_USER.SUCCESS:
      return action.payload.data.school ? action.payload.data.school : state;

    case LOGOUT.REQUEST:
      return null;

    default:
      return state;
  }
}
