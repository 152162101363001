import { Dict } from '@dabapps/redux-api-collections/dist/utils';
import moment from 'moment';
import { AnyAction } from 'redux';

import { generateMonthRange } from '^/common/utils/date-ranges';
import { getStatsPeriodFromMoment } from '^/common/utils/stats-periods';
import {
  GET_ACTIVITY_HIGHLIGHT_STATS,
  GET_OLDEST_ACTIVITY_DATE,
  SET_CURRENT_PERIOD,
} from './actions';
import { ActivityHighlightStats, StatsPeriod } from './types';

export type ActivityHighlightStatsState = Dict<ActivityHighlightStats | null>;

export function activityHighlightStats(
  state: ActivityHighlightStatsState = {},
  action: AnyAction
): ActivityHighlightStatsState {
  switch (action.type) {
    case GET_ACTIVITY_HIGHLIGHT_STATS.SUCCESS:
      return {
        ...state,
        [action.meta.tag]: action.payload.data
          ? ActivityHighlightStats(action.payload.data)
          : null,
      };
    default:
      return state;
  }
}

export function availableStatsPeriods(
  state: ReadonlyArray<StatsPeriod> = [],
  action: AnyAction
) {
  switch (action.type) {
    case GET_OLDEST_ACTIVITY_DATE.SUCCESS:
      const oldestDate = moment.utc(action.payload.data.oldest_activity_date);
      const todaysDate = moment.utc();

      return generateMonthRange(oldestDate, todaysDate)
        .map(getStatsPeriodFromMoment)
        .reverse();

    default:
      return state;
  }
}

export function currentStatsPeriod(
  state: StatsPeriod = getStatsPeriodFromMoment(moment.utc()),
  action: AnyAction
) {
  switch (action.type) {
    case SET_CURRENT_PERIOD:
      return action.payload;
    default:
      return state;
  }
}
