import React from 'react';
import { isNumber, isUndefined } from 'underscore';
import { InfoCircleFilled } from '@ant-design/icons';

import { GlobalActivityHighlightsProps } from '../types';
import { DashBoardHighlightKeys } from '^/api-services/dashboard/types.ts';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';

import { toReadableNumber } from '^/common/utils/text-utils';

export const ActivityHighlights: React.FC<GlobalActivityHighlightsProps> = ({
  data,
}: GlobalActivityHighlightsProps) => {
  return (
    <div className="activity-highlights">
      {Object.keys(data).map((ele, index) => (
        <ActivityHeaderCard
          count={(data as never)[ele]}
          name={ele}
          key={`dashboard-highlight-card-${index}`}
        />
      ))}
    </div>
  );
};

const ActivityHeaderCard = ({ name, count }: IActivityHeaderCardProps) => {
  const cardBorderStyle = () => {
    return {};
  };

  const isQuarantined = isUndefined(count);

  const generateDescription = () => {
    const val: string = isQuarantined
      ? DASHBOARD_PAGE_LABELS.quarantineDescription
      : DASHBOARD_PAGE_LABELS[`highlights_${name}_description`];
    let days = 0;
    switch (name) {
      case DashBoardHighlightKeys.IU:
        days = 14;
        break;
      case DashBoardHighlightKeys.TOTAL_WAU:
        days = 30;
        break;
      case DashBoardHighlightKeys.TOTAL_MAU:
        days = 7;
        break;
    }
    if (days)
      return (
        <span>
          {val + ' '}
          <strong className="highlight">
            {isQuarantined ? days * 2 : days} {DASHBOARD_PAGE_LABELS.days}
          </strong>
          {isQuarantined && DASHBOARD_PAGE_LABELS.quarantineDescriptionEnd}
        </span>
      );
    return <>{val}</>;
  };

  return (
    <div className="activity-header-card" style={cardBorderStyle()}>
      <div
        className={`highlight-column ${
          isQuarantined ? ' quarantined-col-1' : 'col-1'
        }`}
      >
        {isQuarantined ? (
          <InfoCircleFilled />
        ) : (
          <>
            <span className="title">
              {DASHBOARD_PAGE_LABELS[`highlights_${name}`]}
            </span>
            <span className="description">{generateDescription()}</span>
          </>
        )}
      </div>
      {isQuarantined ? (
        <div className="highlight-column quarantined-col-2">
          <span className="title">
            {DASHBOARD_PAGE_LABELS[`highlights_${name}`]}
          </span>
          <span className="description">{generateDescription()}</span>
        </div>
      ) : (
        <div className="highlight-column align-items-end col-2">
          <span className="count">
            {isNumber(count) ? toReadableNumber(count) : 'Quarantined'}
          </span>
        </div>
      )}
    </div>
  );
};

interface IActivityHeaderCardProps {
  name: string;
  count: number;
}
