import { AnyAction } from 'redux';

import {
  CHANGE_FULL_ASSESSMENT_PAGE,
  TOGGLE_ASSESSMENT_TYPE,
} from '^/app/authenticated/assessments/actions';

export interface AssessmentModalState {
  isFullAssessment: boolean;
  fullAssessmentPageNumber: number;
}

export function isFullAssessment(
  state: boolean = false,
  action: AnyAction
): boolean {
  switch (action.type) {
    case TOGGLE_ASSESSMENT_TYPE:
      return !state;
    default:
      return state;
  }
}

export function fullAssessmentPageNumber(
  state: number = 1,
  action: AnyAction
): number {
  switch (action.type) {
    case CHANGE_FULL_ASSESSMENT_PAGE:
      return action.pageNumber ? action.pageNumber : state + 1;
    default:
      return state;
  }
}
