import { Dict } from '@dabapps/simple-records';
import { AnyAction } from 'redux';

import {
  ADD_FILTER_OPTION,
  REMOVE_FILTER_OPTION,
  UPDATE_SEARCH_VALUE,
} from './actions';
import { FilterOption } from './types';
import { addFilterToFilters, removeFilterFromFilters } from './utils';

export type SelectedDashboardFilters = {
  filters: Dict<ReadonlyArray<FilterOption>>;
  searchQuery: string;
};
type FilterPayload = Readonly<{ filter: string; option: FilterOption }>;

export function selectedDashboardFilters(
  state: SelectedDashboardFilters = {
    filters: {} as Dict<ReadonlyArray<FilterOption>>,
    searchQuery: '',
  },
  action: AnyAction
): SelectedDashboardFilters {
  switch (action.type) {
    case ADD_FILTER_OPTION: {
      const { filter, option }: FilterPayload = action.payload;
      return addFilterToFilters(state, filter, option);
    }
    case REMOVE_FILTER_OPTION: {
      const { filter, option }: FilterPayload = action.payload;
      return removeFilterFromFilters(state, filter, option);
    }
    case UPDATE_SEARCH_VALUE: {
      return { ...state, searchQuery: action.payload.searchQuery };
    }
    default:
      return state;
  }
}
