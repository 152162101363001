import { Column, Container, ContentBox, Row, SpacedGroup } from '@dabapps/roe';
import DashboardApi from '^/api-services/dashboard/dashboard.service';
import {
  IGraphDetailsRequest,
  IGraphDetailsResponse,
} from '^/api-services/dashboard/types';
import useAPI from '^/api-services/useApi';
import Breadcrumbs from '^/app/authenticated/breadcrumbs';
import { BasicSearchField } from '^/app/components';
import {
  CLICK_DEBOUNCE_TIME,
  DATE_RANGE_FORMAT,
  PAGE_SIZE,
  SEARCH_FIELD_MAXLENGTH,
} from '^/common/constants';
import { RouteConstants } from '^/common/route-constants';
import { Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'underscore';
import { GraphUserListUser } from './types';
import moment from 'antd/node_modules/moment';
import { FEELING_NAMES } from '^/app/authenticated/activities/constants';
import { USER_LABELS } from '^/common/labels-english';

export const GraphDetails: React.FC<any> = (props: any) => {
  const { location, history } = props;
  const [searchQuery, setSearchQuery] = useState('');
  const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: PAGE_SIZE,
  });

  const { callAPI: fetchDashboardGraphData, ...graphUsersData } = useAPI<
    IGraphDetailsRequest,
    IGraphDetailsResponse
  >(DashboardApi.fetchGraphDetails);
  const throttledGetDashboardGraphApi = useCallback(
    debounce(fetchDashboardGraphData, CLICK_DEBOUNCE_TIME),
    []
  );

  useEffect(() => {
    let req = location.state?.filters;
    if (req) {
      req = {
        ...req,
        chartType: location.state?.type,
        chartValue: JSON.stringify(location.state?.selectedType),
        pageNumber: pageInfo.page,
        pageSize: pageInfo.pageSize,
        search: searchQuery,
      };
      throttledGetDashboardGraphApi(req);
    }
  }, [location.state, pageInfo, searchQuery]);

  useEffect(() => {
    if (!location.state) {
      history.push(RouteConstants.dashboard.route);
    }
  }, []);

  const getColumns = useMemo(() => {
    const columns: ColumnsType<GraphUserListUser> = [
      {
        title: 'Name',
        dataIndex: 'name',
        fixed: 'left',
        // eslint-disable-next-line react/display-name
        render: (_: undefined, row: GraphUserListUser) => (
          <>
            <p className="margin-none">
              {row.first_name} {row.last_name}
            </p>
          </>
        ),
      },
      {
        title: 'Created Date',
        dataIndex: 'created',
        // eslint-disable-next-line react/display-name
        render: (value: string) => moment(value).format(DATE_RANGE_FORMAT),
      },
    ];

    return columns;
  }, []);

  const changePage = (page: number, pageSize?: number) => {
    setPageInfo({
      page: page,
      pageSize: pageSize ? pageSize : pageInfo.pageSize,
    });
  };

  const onSearchQueryInput = (input: string) => {
    setSearchQuery(input);
  };

  return (
    <Container className="margin-top-large">
      <Row>
        <Column sm={12} md={3}>
          <Breadcrumbs
            breadcrumbs={[{ label: 'Users', path: '/graph-details' }]}
          />
        </Column>
        <Column sm={12} md={9}>
          <SpacedGroup className="display-block text-align-right">
            <BasicSearchField
              className="user-search-input"
              placeholder={USER_LABELS.searchUsers}
              maxLength={SEARCH_FIELD_MAXLENGTH}
              value={searchQuery}
              onChange={(e) => onSearchQueryInput(e.target.value)}
              allowClear={true}
              //   onSearch={(e) => throttledGetUserApi(e)}
              size="small"
              style={{ width: '16em' }}
              loading={searchQuery ? graphUsersData.loading : false}
            />
          </SpacedGroup>
        </Column>
      </Row>

      <ContentBox>
        <Pagination
          current={pageInfo.page}
          total={graphUsersData.response?.count}
          pageSize={pageInfo.pageSize}
          onChange={changePage}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showSizeChanger
          disabled={graphUsersData.loading}
        />
        <Table
          rowKey="id"
          columns={getColumns}
          dataSource={graphUsersData.response?.results || []}
          pagination={false}
          loading={graphUsersData.loading}
        />
      </ContentBox>
    </Container>
  );
};
