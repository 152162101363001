import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { StatsPeriod } from '^/app/authenticated/activities/overview/stats/types';
import { formatPeriod } from '^/common/utils/stats-periods';

export const GET_ACTIVITY_HIGHLIGHT_STATS = makeAsyncActionSet(
  'GET_ACTIVITY_HIGHLIGHT_STATS'
);
export function getActivityHighlightStats(
  school: string,
  year: number,
  month: number
) {
  const period = formatPeriod(year, month);
  return request(
    GET_ACTIVITY_HIGHLIGHT_STATS,
    `/api/activities/school-summary/${period}/?school=${school}`,
    'GET',
    undefined,
    { tag: period }
  );
}

export const GET_OLDEST_ACTIVITY_DATE = makeAsyncActionSet(
  'GET_OLDEST_ACTIVITY_DATE'
);
export function getOldestActivityDate(school: string) {
  return request(
    GET_OLDEST_ACTIVITY_DATE,
    `/api/activities/school-oldest-logged-activity/?school=${school}`,
    'GET'
  );
}

export const SET_CURRENT_PERIOD = 'SET_CURRENT_PERIOD';
export function setCurrentPeriod(period: StatsPeriod) {
  return {
    type: SET_CURRENT_PERIOD,
    payload: period,
  };
}
