import { makeAsyncActionSet, request } from '@dabapps/redux-requests';

export const GET_ACTIVITIES_SUMMARY = makeAsyncActionSet(
  'GET_ACTIVITIES_SUMMARY'
);
export function getActivitiesSummary(studentId: string) {
  return request(
    GET_ACTIVITIES_SUMMARY,
    `/api/activities/student-summary/${studentId}/`,
    'GET',
    undefined,
    { tag: studentId }
  );
}
