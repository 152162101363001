import React from 'react';
import { Modal } from 'antd';
import { CLUSTER_ADMIN_LABELS } from '^/common/labels-english';
import ClusterApis from '^/api-services/cluster/cluster.service';
import useAPI from '^/api-services/useApi';
import { toast } from 'react-toastify';

interface IConfirmDeleteCluster {
  onCancel: () => void;
  clusterID: string;
}

export const ConfirmDeleteCluster: React.FC<IConfirmDeleteCluster> = ({
  onCancel,
  clusterID,
}) => {
  const { deleteConfirm, yes } = CLUSTER_ADMIN_LABELS;

  const { callAPI: deleteCluster, loading } = useAPI(ClusterApis.deleteCluster);

  const onDeleteCluster = () => {
    if (clusterID)
      deleteCluster(
        clusterID,
        () => {
          onCancel();
          toast.success(CLUSTER_ADMIN_LABELS.deleteSuccess);
        },
        () => toast.error(CLUSTER_ADMIN_LABELS.error)
      );
  };

  return (
    <Modal
      destroyOnClose
      visible
      title={deleteConfirm.title}
      okText={yes}
      maskClosable={false}
      onCancel={() => !loading && onCancel()}
      onOk={onDeleteCluster}
      confirmLoading={loading}
    >
      {deleteConfirm.content}
    </Modal>
  );
};
