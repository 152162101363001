import React, { useEffect, useState } from 'react';
import './graph-container-styles.less';

import { GraphCard } from '^/app/components';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { ConfirmButton } from '^/app/components';
import { SummaryGraphContainerProps } from '..';
import { ActivityHighlights } from '../highlights/activity-highlights';
import { GenericPieGraph } from './generic-pie-graph';
import { ActivitySummaryGraph } from './activity-summary-bargraph';
import {
  DashBoardHighlightKeys,
  IDashboardHistogramMap,
  IDashboardUIHistogram,
  ISummaryHistograms,
} from '^/api-services/dashboard/types';
import { toTitleCase } from '^/common/utils/text-utils';
import { Space } from 'antd';
import {
  ActivitiesNames,
  ActivityLocationNames,
} from '^/common/activities-constants';
import { ETHNICITY_DISPLAY } from '^/common/users/constants';

// colors

const colorPalette = [
  '#29b6f6',
  '#104962',
  '#3ebdf7',
  '#69ccf9',
  '#7fd3fa',
  '#94dbfb',
  '#bfe9fc',
  '#155b7b',
  '#196d94',
  '#29b6f6',
  '#2192c5',
  '#25a4dd',
];

export const SummaryGraphContainer: React.FC<SummaryGraphContainerProps> = ({
  data,
  exportData,
  handleGraphClick,
}: SummaryGraphContainerProps) => {
  const [isMinutesBreakdownShown, setGraphToggle] = useState(true);

  const [histograms, setHistograms] = useState<{
    [key: string]: IDashboardUIHistogram[];
  }>({});

  const [headerData, setHeaderData] = useState({
    [DashBoardHighlightKeys.SUMMARY_TOTAL_STUDENTS]: { count: 0 },
    [DashBoardHighlightKeys.SUMMARY_TOTAL_ACTIVE_TIME]: { count: 0 },
    [DashBoardHighlightKeys.SUMMARY_TOTAL_ACTIVE_SESSIONS]: { count: 0 },
    [DashBoardHighlightKeys.SUMMARY_AVERAGE_ACTIVE_TIME]: { count: 0 },
    [DashBoardHighlightKeys.SUMMARY_AVERAGE_ACTIVE_SESSIONS]: { count: 0 },
  });

  const [headerTextData, setHeaderTextData] = useState({
    [DashBoardHighlightKeys.SUMMARY_MOST_ACTIVE_DAY]: { count: '' },
    [DashBoardHighlightKeys.SUMMARY_MOST_ACTIVE_ACTIVITY]: { count: '' },
    [DashBoardHighlightKeys.SUMMARY_MOST_COMMON_PLACE]: { count: '' },
    [DashBoardHighlightKeys.SUMMARY_MOST_COMMON_EMOTION]: { count: '' },
    [DashBoardHighlightKeys.SUMMARY_OUT_OF_SCHOOL_STUDENTS]: { count: '' },
  });

  const transformMap = {
    activity: (val: string) =>
      val !== 'OTHERS'
        ? ActivitiesNames.find((activity) => activity.type === val)?.label
        : 'Other',
    // ethnicity: (val: string) => ETHNICITY_DISPLAY[val] || 'NA',
    ethnicity: (val: string) => val,
    location: (val: string) =>
      val !== 'OTHERS'
        ? ActivityLocationNames.find((activity) => activity.type === val)?.label
        : 'Other',
  };

  const constructHistogram = (
    histogramMap: IDashboardHistogramMap,
    transform?: (val: string) => string
  ) => {
    const histogram = Object.keys(histogramMap)
      .map((k: string, i: number) => ({
        name: transform ? transform(k) : toTitleCase(k, '_'),
        sessions: histogramMap[k].sessions || 0,
        duration: histogramMap[k].duration || 0,
        color: colorPalette[i],
      }))
      .sort((a, b) => {
        return a.duration > b.duration ? -1 : a.duration < b.duration ? 1 : 0;
      });
    return histogram;
  };

  useEffect(() => {
    if (data && data.summary_metrics) {
      const { summary_metrics } = data;

      setHeaderData({
        [DashBoardHighlightKeys.SUMMARY_TOTAL_STUDENTS]: {
          count: summary_metrics.summary_total_students,
        },
        [DashBoardHighlightKeys.SUMMARY_TOTAL_ACTIVE_TIME]: {
          count: summary_metrics.summary_total_active_time,
        },
        [DashBoardHighlightKeys.SUMMARY_TOTAL_ACTIVE_SESSIONS]: {
          count: summary_metrics.summary_total_active_sessions,
        },
        [DashBoardHighlightKeys.SUMMARY_AVERAGE_ACTIVE_TIME]: {
          count: Math.round(summary_metrics.summary_average_active_time),
        },
        [DashBoardHighlightKeys.SUMMARY_AVERAGE_ACTIVE_SESSIONS]: {
          count: Math.round(summary_metrics.summary_average_active_sessions),
        },
      });

      let histogramsMap: { [key: string]: IDashboardUIHistogram[] } = {};
      let k: keyof ISummaryHistograms;

      for (k in summary_metrics) {
        if (k.indexOf('summary') < 0) {
          const transform = transformMap[k] ? transformMap[k] : undefined;
          histogramsMap = {
            ...histogramsMap,
            [k]: constructHistogram(summary_metrics[k], transform),
          };
        }
      }

      setHeaderTextData({
        [DashBoardHighlightKeys.SUMMARY_MOST_ACTIVE_DAY]: {
          count:
            histogramsMap['week_day'] &&
            histogramsMap['week_day'].length > 0 &&
            histogramsMap['week_day'][0].name
              ? histogramsMap['week_day'][0].name
              : '-',
        },
        [DashBoardHighlightKeys.SUMMARY_MOST_ACTIVE_ACTIVITY]: {
          count:
            histogramsMap['activity'] &&
            histogramsMap['activity'].length > 0 &&
            histogramsMap['activity'][0].name
              ? histogramsMap['activity'][0].name
              : '-',
        },
        [DashBoardHighlightKeys.SUMMARY_MOST_COMMON_PLACE]: {
          count:
            histogramsMap['location'] &&
            histogramsMap['location'].length > 0 &&
            histogramsMap['location'][0].name
              ? histogramsMap['location'][0].name
              : '-',
        },
        [DashBoardHighlightKeys.SUMMARY_MOST_COMMON_EMOTION]: {
          count:
            histogramsMap['feeling'] &&
            histogramsMap['feeling'].length > 0 &&
            histogramsMap['feeling'][0].name
              ? histogramsMap['feeling'][0].name
              : '-',
        },
        [DashBoardHighlightKeys.SUMMARY_OUT_OF_SCHOOL_STUDENTS]: {
          count: `${summary_metrics.summary_unique_out_of_school_students}`,
        },
      });

      setHistograms(histogramsMap);
    }
  }, [data]);

  const valueKey = isMinutesBreakdownShown ? 'duration' : 'sessions';

  return (
    <div className="graph-wrapper">
      <ActivityHighlights data={headerData} />
      <ActivityHighlights small={true} data={headerTextData} />
      <div className="flex-1 mb-3">
        <Space className="card-header">
          <ConfirmButton
            onConfirm={exportData}
            buttonTitle={DASHBOARD_PAGE_LABELS.export_data}
            className="dashboard-export-button"
            popConfirmMessages={{
              success: DASHBOARD_PAGE_LABELS.export_data_popup_success,
              failure: DASHBOARD_PAGE_LABELS.export_data_popup_failure,
              title: DASHBOARD_PAGE_LABELS.export_data_popup_title,
            }}
            placement="bottomLeft"
            icon={<CloudDownloadOutlined />}
          />
        </Space>
      </div>
      <div className="flex-1 mb-3">
        <GraphCard
          title={DASHBOARD_PAGE_LABELS.sessions_breakdown_graph_title}
          title2={DASHBOARD_PAGE_LABELS.activity_breakdown_graph_title}
          height="50%"
          width="100%"
          onToggleChange={setGraphToggle}
        >
          <div className="graph-container">
            <div className="small-graph-col-ind">
              <div className="generic-pie-chart-container"></div>

              <GenericPieGraph
                data={histograms['gender'] || []}
                title={'Gender'}
                valueKey={valueKey}
                width={'100%'}
                height={'140%'}
                handleClick={handleGraphClick}
              />
              <div className="generic-pie-chart-container"></div>
              <GenericPieGraph
                title={'SEND'}
                data={histograms['is_send_registered'] || []}
                valueKey={valueKey}
                width={'100%'}
                height={'140%'}
                handleClick={handleGraphClick}
              />
              <div className="generic-pie-chart-container"></div>
            </div>
          </div>
        </GraphCard>
      </div>
      <div className="graph-container mb-3">
        <div className="small-graph-col">
          <GraphCard
            title={DASHBOARD_PAGE_LABELS.year_summary_graph_title}
            height="100%"
            width="100%"
          >
            <GenericPieGraph
              title={'Year Group'}
              data={histograms['year_group'] || []}
              valueKey={valueKey}
              handleClick={handleGraphClick}
            />
          </GraphCard>
        </div>
        <div className="small-right-graph-col">
          <GraphCard
            title={DASHBOARD_PAGE_LABELS.class_summary_graph_title}
            height="100%"
            width="100%"
            cardBodyPadding="1em .5em"
          >
            <ActivitySummaryGraph
              title={DASHBOARD_PAGE_LABELS.class_summary_graph_title}
              data={
                histograms['class_group']
                  ? histograms['class_group'].slice(0, 10)
                  : []
              }
              colors={colorPalette}
              valueKey={valueKey}
              handleClick={handleGraphClick}
            />
          </GraphCard>
        </div>
      </div>

      <div className="flex-1 mb-3">
        <GraphCard
          title={DASHBOARD_PAGE_LABELS.ethnicity_summary_graph_title}
          height="50%"
          width="100%"
        >
          <ActivitySummaryGraph
            title={DASHBOARD_PAGE_LABELS.ethnicity_summary_graph_title}
            data={histograms['ethnicity'] || []}
            colors={colorPalette}
            valueKey={valueKey}
            seriesLabelPrefix={'Avg.'}
            customFormatter={(val) => ETHNICITY_DISPLAY[val] || 'NA'}
            handleClick={handleGraphClick}
          />
        </GraphCard>
      </div>

      <div className="flex-1 mb-3">
        <GraphCard
          title={DASHBOARD_PAGE_LABELS.activity_summary_graph_title}
          height="50%"
          width="100%"
        >
          <ActivitySummaryGraph
            title={DASHBOARD_PAGE_LABELS.activity_summary_graph_title}
            data={
              histograms['activity'] ? histograms['activity'].slice(0, 10) : []
            }
            colors={colorPalette}
            valueKey={valueKey}
            handleClick={handleGraphClick}
          />
        </GraphCard>
      </div>
    </div>
    // </div>
  );
};
