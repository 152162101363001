import { RecordWithConstructor, SimpleRecord } from '@dabapps/simple-records';

export type ActivityHighlightStatsChangeStudent = Readonly<{
  id: string;
  first_name?: string;
  last_name?: string;
}>;

export const ActivityHighlightStatsChangeStudent = SimpleRecord<
  ActivityHighlightStatsChangeStudent
>({
  id: '',
  first_name: '',
  last_name: '',
});

export type ActivityHighlightStatsChangeInput = Readonly<{
  id: string;
  activity_minutes_difference_to_previous_week: number;
  student: ActivityHighlightStatsChangeStudent;
}>;
export type ActivityHighlightStatsChange = Readonly<{
  id: string;
  activity_minutes_difference_to_previous_week: number;
  student: ActivityHighlightStatsChangeStudent;
}>;

export const ActivityHighlightStatsChange = RecordWithConstructor<
  ActivityHighlightStatsChangeInput,
  ActivityHighlightStatsChange
>(
  {
    id: '',
    activity_minutes_difference_to_previous_week: 0,
    student: { id: '' },
  },
  (input) => {
    return {
      ...input,
      student: ActivityHighlightStatsChangeStudent(input.student),
    };
  }
);

export type ActivityHighlightStatsInput = Readonly<{
  total_time_current_period: number;
  total_time_previous_period: number;
  largest_activity_increases: ReadonlyArray<ActivityHighlightStatsChangeInput>;
  largest_activity_decreases: ReadonlyArray<ActivityHighlightStatsChangeInput>;
}>;
export type ActivityHighlightStats = Readonly<{
  total_time_current_period: number;
  total_time_previous_period: number;
  largest_activity_increases: ReadonlyArray<ActivityHighlightStatsChange>;
  largest_activity_decreases: ReadonlyArray<ActivityHighlightStatsChange>;
}>;

export const ActivityHighlightStats = RecordWithConstructor<
  ActivityHighlightStatsInput,
  ActivityHighlightStats
>(
  {
    total_time_current_period: 0,
    total_time_previous_period: 0,
    largest_activity_increases: [],
    largest_activity_decreases: [],
  },
  (input) => {
    return {
      ...input,
      largest_activity_increases: input.largest_activity_increases.map(
        ActivityHighlightStatsChange
      ),
      largest_activity_decreases: input.largest_activity_decreases.map(
        ActivityHighlightStatsChange
      ),
    };
  }
);

export type StatsPeriod = Readonly<{
  readonly month: number;
  readonly year: number;
  readonly periodString: string;
}>;

// new types
export interface getActivityApiRequest {
  school: string;
  year: number;
  month: number;
}
export interface getActivityApiResponse {
  total_time_current_period: number;
  total_time_previous_period: number;
  largest_activity_increases: Largestactivityincrease[];
  largest_activity_decreases: any[];
}

interface Largestactivityincrease {
  id: string;
  activity_minutes_difference_to_previous_week: number;
  student: Student;
}

interface Student {
  id: string;
  first_name: string;
  last_name: string;
}
