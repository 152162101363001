import React, { useCallback, useEffect, useState } from 'react';
import { Modal, Button, Table, Popconfirm } from 'antd';
import { toast } from 'react-toastify';
import { DeleteOutlined } from '@ant-design/icons';
import {
  ClusterAdminColumnsIndex,
  ClusterAdminTableTypes,
  ISchoolModal,
  ISchoolRecord,
} from './types';
import { CLUSTER_ADMIN_LABELS } from '^/common/labels-english';
import useAPI from '^/api-services/useApi';
import SchoolsApi from '^/api-services/schools/schools.service';
import { INPUT_DEBOUNCE_TIME, PAGE_SIZE } from '^/common/constants';
import { SelectFilter } from '^/app/components';
import ClusterApis from '^/api-services/cluster/cluster.service';
import { IEditCluster } from '^/api-services/cluster/types';
import { debounce } from 'underscore';

export default function SchoolModal({
  closeModal,
  clusterID,
}: ISchoolModal): JSX.Element {
  const {
    callAPI: getClusterDetails,
    response: clusterDetails,
    loading: loadingClusterDetails,
  } = useAPI(ClusterApis.getClusterDetails);

  const { callAPI: getAllSchools, ...getAllSchoolsResponse } = useAPI(
    SchoolsApi.fetchSchoolsData
  );
  const { callAPI: editCluster, loading: editClusterLoading } = useAPI(
    ClusterApis.editClusterDetails
  );
  const loading = loadingClusterDetails || getAllSchoolsResponse.loading;
  const labels = CLUSTER_ADMIN_LABELS.schoolModal;
  const [pageNumber, setPageNumber] = useState(1);
  const [schoolSelected, setSchoolSelected] = useState<string | undefined>(
    undefined
  );
  const [tableData, setTableData] = useState<ISchoolRecord[]>([]);

  const callGetAllSchoolsApi = (search = '') => {
    getAllSchools({
      page: pageNumber,
      page_size: PAGE_SIZE,
      search,
    });
  };

  const throttledAllSchoolsApi = useCallback(
    debounce(callGetAllSchoolsApi, INPUT_DEBOUNCE_TIME),
    []
  );

  useEffect(() => {
    if (clusterID) {
      getClusterDetails(clusterID);
    }
  }, [clusterID]);

  useEffect(() => {
    if (clusterDetails?.name) {
      const schools: ISchoolRecord[] = (clusterDetails?.schools || []).map(
        (school) => {
          return {
            id: school.id,
            name: school.name,
            location: school.location,
            type: ClusterAdminTableTypes.EXISTING,
          };
        }
      );
      setTableData(schools);
    }
  }, [clusterDetails]);

  useEffect(callGetAllSchoolsApi, [pageNumber]);

  const rowBgColor = (type: ClusterAdminTableTypes) => {
    switch (type) {
      case ClusterAdminTableTypes.ADD:
        return 'add-row';
      case ClusterAdminTableTypes.DELETE:
        return 'delete-row';
      case ClusterAdminTableTypes.EXISTING:
        return 'existing-row';
      default:
        return '';
    }
  };

  const onAdd = () => {
    const index = tableData.findIndex((ele) => ele.id === schoolSelected);
    if (index === -1) {
      const school = getAllSchoolsResponse.response?.results.find(
        (school) => school.id === schoolSelected
      );
      if (school) {
        const newData = [
          {
            ...school,
            [ClusterAdminColumnsIndex.TYPE]: ClusterAdminTableTypes.ADD,
          },
          ...tableData,
        ];
        setTableData(newData);
        setSchoolSelected(undefined);
        callGetAllSchoolsApi();
      }
    }
  };

  const onDelete = (record: ISchoolRecord) => {
    const newData = [...tableData];
    const index = newData.findIndex((item) => item.id === record.id);
    if (record.type === ClusterAdminTableTypes.ADD) {
      newData.splice(index, 1);
    } else {
      newData.splice(index, 1, {
        ...record,
        type: ClusterAdminTableTypes.DELETE,
      });
    }
    setTableData(newData);
  };

  const columns = [
    {
      title: labels.nameCol,
      dataIndex: 'name',
    },
    {
      title: labels.locationCol,
      dataIndex: 'location',
    },
    {
      title: '',
      render: (_: undefined, row: ISchoolRecord) => {
        return (
          <Popconfirm
            title={CLUSTER_ADMIN_LABELS.areYouSure}
            okText={CLUSTER_ADMIN_LABELS.yes}
            cancelText={CLUSTER_ADMIN_LABELS.no}
            onConfirm={() => onDelete(row)}
            overlayClassName="cluster-popover"
          >
            <Button
              shape="circle"
              type="link"
              icon={<DeleteOutlined />}
              size="large"
              title={CLUSTER_ADMIN_LABELS.columns.delete}
            />
          </Popconfirm>
        );
      },
    },
  ];

  const onSubmit = () => {
    const deletedSchools = [];
    const addedSchools = [];
    for (let school of tableData) {
      switch (school.type) {
        case ClusterAdminTableTypes.ADD:
          addedSchools.push(school.id as string);
          break;
        case ClusterAdminTableTypes.DELETE:
          deletedSchools.push(school.id as string);
          break;
      }
    }
    const editRequest: IEditCluster = {
      id: clusterID,
      schools_to_add: addedSchools,
      schools_to_remove: deletedSchools,
      admins_to_add: [],
      admins_to_remove: [],
      admins_to_update: [],
    };
    editCluster(
      editRequest,
      () => {
        closeModal();
        toast.success(CLUSTER_ADMIN_LABELS.clusterEditSuccess);
      },
      () => {
        toast.error(CLUSTER_ADMIN_LABELS.error);
      }
    );
  };

  return (
    <Modal
      title={labels.Title}
      visible={true}
      onCancel={closeModal}
      footer={null}
      destroyOnClose
      maskClosable={false}
      className="cluster-school-modal-wrapper"
      closable={!editClusterLoading}
    >
      <div className="school-select-container">
        <SelectFilter
          className="dropdown-paginated-container"
          style={{ width: '100%' }}
          placeholder="Select"
          optionList={getAllSchoolsResponse.response?.results || []}
          totalCount={getAllSchoolsResponse.response?.count}
          currentPageNumber={pageNumber}
          onPageChange={setPageNumber}
          onChange={setSchoolSelected}
          value={schoolSelected}
          showSearch
          onSearch={throttledAllSchoolsApi}
          onClear={() => setSchoolSelected(undefined)}
          loading={loading}
        />
        <div className="submit-btn-container">
          <Button
            type="primary"
            shape="round"
            onClick={onAdd}
            disabled={!schoolSelected}
          >
            {labels.addBtn}
          </Button>
        </div>
      </div>
      <Table
        columns={columns}
        rowKey="id"
        loading={loading}
        dataSource={tableData}
        pagination={{
          hideOnSinglePage: true,
          position: ['bottomCenter'],
          pageSize: 10,
          showSizeChanger: false,
        }}
        rowClassName={(record: ISchoolRecord) => rowBgColor(record.type)}
      />
      <div className="submit-btn-container">
        <Button
          shape="round"
          type="primary"
          block
          className="submit-btn mt-3"
          loading={editClusterLoading}
          disabled={!tableData.length}
          onClick={onSubmit}
        >
          {CLUSTER_ADMIN_LABELS.submitBtn}
        </Button>
      </div>
    </Modal>
  );
}
