import { Column, Container, Row } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';

import LoginPage from '^/app/unauthenticated/login';
import ResetPasswordPage from '^/app/unauthenticated/reset-password';
import ResetPasswordConfirmationPage from '^/app/unauthenticated/reset-password/reset-password-confirmation-page';
import NotFoundPage from '^/common/not-found-page';
import PrivacyPolicy from '^/common/privacy-policy';
import TermsAndConditions from '^/common/terms-and-conditions';

export class Unauthenticated extends React.PureComponent<RouteComponentProps> {
  public render() {
    return (
      <Container className="margin-top-large">
        <Row>
          <Column sm={10} md={8} lg={6} smPush={1} mdPush={2} lgPush={3}>
            <Switch>
              <Route
                path="/reset-password/:uid/:token/"
                component={ResetPasswordConfirmationPage}
              />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Route exact path="/privacy-policy" component={PrivacyPolicy} />
              <Route
                exact
                path="/terms-and-conditions"
                component={TermsAndConditions}
              />
              <Route exact path="/" component={LoginPage} />
              <Route component={NotFoundPage} />
            </Switch>
          </Column>
        </Row>
      </Container>
    );
  }
}

export default withRouter(connect()(Unauthenticated));
