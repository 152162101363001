import { makeAsyncActionSet, request } from '@dabapps/redux-requests';
import { push } from 'connected-react-router';
import { Dispatch } from 'redux';
import { isSuccessResponse } from '^/common/utils/responses';
import { SchoolSettingsList } from './types';
import { toast } from 'react-toastify';
import { RouteConstants } from '^/common/route-constants';
import { collections } from '^/common/collections';

const {
  actions: { getCollection },
} = collections;

export const EDIT_SOCIAL_SETTINGS = makeAsyncActionSet('EDIT_SOCIAL_SETTINGS');
export function editSocialSettings(data: Partial<SchoolSettingsList>) {
  return (dispatch: Dispatch) => {
    return request(
      EDIT_SOCIAL_SETTINGS,
      `/api/profile/social-settings-list/${data.id}/`,
      'PATCH',
      data
    )(dispatch).then((response) => {
      if (response && isSuccessResponse(response)) {
        dispatch(push(RouteConstants.schoolSettings.route));
        toast.success(`Social Settings for ${data.year_group} updated`);
        dispatch(getCollection('profile/social-settings-list'));
      }
    });
  };
}
