import React from 'react';
import {
  Bar,
  BarChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  GRAPH_AXIS_LABEL_MAXLENGTH,
  GRAPH_TOOLTIP_COLOR,
} from '^/common/constants';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';
import { toReverseTitleCase, toTitleCase } from '^/common/utils/text-utils';
import { IActivityHistogramGraphProps } from './types';

export const ActivitySummaryGraph: React.FC<IActivityHistogramGraphProps> = ({
  title,
  data,
  colors,
  valueKey,
  customFormatter,
  seriesLabelPrefix,
  handleClick,
}: IActivityHistogramGraphProps) => {
  const XAxisTickerFormatter = (val: string) => {
    const returnVal = val;

    if (returnVal && returnVal.length > GRAPH_AXIS_LABEL_MAXLENGTH) {
      return returnVal.substr(0, GRAPH_AXIS_LABEL_MAXLENGTH - 4) + '...';
    }
    return returnVal;
  };

  const underScoreFormater = (val: string) => {
    return toTitleCase(val, '_');
  };

  const tooltipLabel = (val: string) => {
    return val;
  };

  const renderClick = (param: string, label: string) => {
    switch (param) {
      case DASHBOARD_PAGE_LABELS.class_summary_graph_title:
        return {
          type: 'SUMMARY_CLASS_GROUP',
          selectedType: { data: toReverseTitleCase(label, '_') },
        };
      case DASHBOARD_PAGE_LABELS.ethnicity_summary_graph_title:
        return {
          type: 'SUMMARY_ETHNICITY',
          selectedType: {
            data: toReverseTitleCase(label, '_'),
          },
        };
      case DASHBOARD_PAGE_LABELS.activity_summary_graph_title:
        return {
          type: 'SUMMARY_ACTIVITY',
          selectedType: {
            data: toReverseTitleCase(label, '_'),
          },
        };
      default:
        return {
          type: 'SUMMARY_CLASS_GROUP',
          selectedType: { data: toReverseTitleCase(label, '_') },
        };
    }
  };

  return (
    <div className="histogram-bar-chart-container">
      {Array.isArray(data) && data.length ? (
        <ResponsiveContainer height="100%" width="100%">
          <BarChart
            data={data}
            layout="horizontal"
            margin={{ top: 10, bottom: 10, right: 10, left: 10 }}
            // stackOffset="expand"
            barSize={40}
          >
            <Bar
              dataKey={valueKey || 'minutes'}
              fill={colors[0]}
              name={
                valueKey === 'sessions'
                  ? `${
                      seriesLabelPrefix ? seriesLabelPrefix + ' ' : ''
                    }Sessions`
                  : `${seriesLabelPrefix ? seriesLabelPrefix + ' ' : ''}Minutes`
              }
              onClick={(e) =>
                handleClick ? handleClick(renderClick(title, e.name)) : null
              }
              style={{ cursor: 'pointer' }}
            />
            <YAxis type="number" />
            <XAxis
              type="category"
              tickFormatter={XAxisTickerFormatter}
              interval={0}
              width={85}
              dataKey="name"
              axisLine={true}
              tickLine={true}
            />
            <Tooltip
              formatter={(value, name) => [
                underScoreFormater(value.toString()),
                underScoreFormater(name.toString()),
              ]}
              cursor={{ fill: GRAPH_TOOLTIP_COLOR }}
              labelFormatter={(val) => {
                return customFormatter
                  ? customFormatter(val.toString())
                  : tooltipLabel(val.toString());
              }}
              itemStyle={{ margin: 0 }}
              wrapperStyle={{ padding: 0 }}
            />
            <Legend
              verticalAlign="top"
              layout="horizontal"
              iconType="circle"
              formatter={underScoreFormater}
            />
          </BarChart>
        </ResponsiveContainer>
      ) : (
        <p className="text-center">{DASHBOARD_PAGE_LABELS.no_data}</p>
      )}
    </div>
  );
};
