import { anyPending, hasFailed } from '@dabapps/redux-requests';
import { ContentBox, ContentBoxHeader } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';

import LoginForm from '^/app/unauthenticated/login/login-form';
import {
  GET_LOGGED_IN_USER,
  LOGIN,
  login,
  LoginData,
  loginSocial,
  LoginSocial,
} from '^/common/authentication/actions';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';
import { StoreState } from '^/store/types';

interface StateProps {
  isLoading: boolean;
  hasLoginFailed: boolean;
  errors?: ResponseErrors;
}

interface DispatchProps {
  login: typeof login;
  loginSocial: typeof loginSocial;
}

type Props = StateProps & DispatchProps;

export class LoginPage extends React.PureComponent<Props> {
  public render() {
    const { isLoading, hasLoginFailed, errors } = this.props;
    return (
      <ContentBox>
        <ContentBoxHeader className="centred">
          <img className="nav-logo" alt="miMove" src="/static/logo.png" />
        </ContentBoxHeader>
        <h2 className="margin-bottom-x-small">Login to miMove</h2>
        <LoginForm
          onSubmit={this.onLogin}
          onLoginSocial={this.onLoginSocial}
          isLoading={isLoading}
          hasFailed={hasLoginFailed}
          errors={errors}
        />
      </ContentBox>
    );
  }
  private onLogin = (data: LoginData) => {
    this.props.login(data);
  };
  private onLoginSocial = (data: LoginSocial) => {
    this.props.loginSocial(data);
  };
}

export function mapStateToProps({ responses }: StoreState) {
  return {
    isLoading: anyPending(responses, [LOGIN, GET_LOGGED_IN_USER]),
    hasLoginFailed: hasFailed(responses, LOGIN),
    errors: getResponseErrors(responses, LOGIN),
  };
}

export default connect(mapStateToProps, {
  login,
  loginSocial,
})(LoginPage);
