import { ResponseErrors } from '^/common/utils/errors';
import { AnyAction } from 'redux';
import { GET_IMPORT_STATUS, QUEUE_IMPORT_USER } from './actions';

export function isImportPendingOrInProgress(
  state: boolean = false,
  action: AnyAction
) {
  switch (action.type) {
    case QUEUE_IMPORT_USER.REQUEST:
    case QUEUE_IMPORT_USER.SUCCESS:
      return true;
    case QUEUE_IMPORT_USER.FAILURE:
      return false;
    case GET_IMPORT_STATUS.SUCCESS:
      if (action.payload.data.is_complete || action.payload.data.has_failed) {
        return false;
      } else {
        return true;
      }
    case GET_IMPORT_STATUS.FAILURE:
      return false;
    default:
      return state;
  }
}

export function importErrors(
  state: ResponseErrors | null = null,
  action: AnyAction
) {
  switch (action.type) {
    case QUEUE_IMPORT_USER.REQUEST:
      return null;
    case GET_IMPORT_STATUS.SUCCESS:
      if (action.payload.data.has_failed) {
        return {
          non_field_errors: action.payload.data.errors,
        };
      } else {
        return null;
      }
    default:
      return state;
  }
}
