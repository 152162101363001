import * as React from 'react';
import {
  CheckCircleOutlined,
  WarningOutlined,
  CloseOutlined,
} from '@ant-design/icons';

interface Props {
  isError?: boolean;
  isSuccess?: boolean;
  isWarning?: boolean;
  message: string;
}

export default function ToastInner(props: Props) {
  return (
    <div className="Toastify__toast-inner">
      {props.isSuccess && <CheckCircleOutlined />}
      {props.isWarning && <WarningOutlined />}
      {props.isError && <CloseOutlined />}
      <span>{props.message}</span>
    </div>
  );
}
