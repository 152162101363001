import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  MutableRefObject,
} from 'react';
import { connect } from 'react-redux';
import moment, { Moment } from 'antd/node_modules/moment';

import {
  CANCEL_API_MSG,
  CLICK_DEBOUNCE_TIME,
  DATE_RANGE_FORMAT,
  ISO_FORMAT,
} from '^/common/constants';
import { StoreState } from '^/store/types';
import { GraphFilters } from './filters/graph-filters';
import { GraphContainer } from './graphs/graph-container';
import { ActivityHighlights } from './highlights/activity-highlights';
import { GraphDashboardProps, HandleGraphClickProps } from './types';
import './activity-dashboard-styles.less';
import useAPI from '^/api-services/useApi';
import DashboardApi from '^/api-services/dashboard/dashboard.service';
import {
  loggedInUserIsAdmin,
  loggedInUserIsClusterAdmin,
  loggedInUserIsClusterManager,
} from '^/common/utils/roles';
import {
  IDashboardGraphDataRequest,
  IDashboardGraphDataResponse,
  IDashboardSummaryDataResponse,
} from '^/api-services/dashboard/types';

import LoadingSpinner from '^/common/loading-spinner';
import { DASHBOARD_PAGE_LABELS } from '^/common/labels-english';
import ActivityApi from '^/api-services/activities/activities.service';
import { debounce } from 'underscore';
import {
  downloadFileFromResponse,
  generateFileNameDate,
  parseContentDisposition,
} from '^/common/utils/download-file';
import axios from '^/api-services/axiosInstance';
import { SummaryGraphContainer } from './graphs/summary-graph-container';
import { Space, Switch } from 'antd';
import { historyObject } from '^/store';

const ActivityDashboard: React.FC<GraphDashboardProps> = (
  props: GraphDashboardProps
) => {
  const { currentSchool, loggedInUser } = props;
  const showSchool =
    loggedInUserIsAdmin(loggedInUser) ||
    loggedInUserIsClusterAdmin(loggedInUser) ||
    loggedInUserIsClusterManager(loggedInUser);
  const [rangeFilter, setRangeFilter] = useState<[Moment, Moment] | null>(null);
  const [classFilter, setClassFilter] = useState<string[]>([]);
  const [groupFilter, setGroupFilter] = useState<string[]>([]);
  const [yearFilter, setYearFilter] = useState<string[]>([]);
  const [genderFilter, setGenderFilter] = useState<string[]>([]);
  const [SENDFilter, setSENDFilter] = useState<string[]>([]);
  const [ethnicityFilter, setEthnicityFilter] = useState<string[]>([]);
  const [EALFilter, setEALFilter] = useState<string[]>([]);
  const [pupilPremiumFilter, setPupilPremiumFilter] = useState<string[]>([]);
  const [selectedSchool, setSelectedSchool] = useState(currentSchool);
  const [showSummary, setShowSummary] = useState(false);
  const cancelToken: MutableRefObject<
    axios.CancelTokenSource | undefined
  > = useRef(undefined);

  useEffect(() => {
    setSelectedSchool(currentSchool);
  }, [currentSchool]);
  const { callAPI: fetchDashboardGraphData, ...dashboardGraphData } = useAPI<
    IDashboardGraphDataRequest,
    IDashboardGraphDataResponse
  >(DashboardApi.fetchDashboardGraphData);
  const throttledGetDashboardGraphApi = useCallback(
    debounce(fetchDashboardGraphData, CLICK_DEBOUNCE_TIME),
    [selectedSchool]
  );

  const { callAPI: fetchSummaryData, ...dashboardSummaryData } = useAPI<
    IDashboardGraphDataRequest,
    IDashboardSummaryDataResponse
  >(DashboardApi.fetchSummaryData);
  const throttledGetSummaryData = useCallback(
    debounce(fetchSummaryData, CLICK_DEBOUNCE_TIME),
    [selectedSchool]
  );

  const {
    callAPI: getOldestActivityLoggedDate,
    ...getOldestActivityLoggedDateResponse
  } = useAPI(ActivityApi.getOldestActivityLoggedDate);
  const { callAPI: fetchDashboardHeaderData, ...dashboardHeaderData } = useAPI(
    DashboardApi.fetchDashboardHeaderData
  );

  const oldestActivityDate =
    getOldestActivityLoggedDateResponse.response?.oldest_activity_date;

  useEffect(() => {
    if (selectedSchool) {
      getOldestActivityLoggedDate({ school: selectedSchool });
      fetchDashboardHeaderData(selectedSchool);
    }
  }, [selectedSchool]);

  const initializeRangeFilter = () => {
    const val = getOldestActivityLoggedDateResponse.response;
    if (val) {
      const today = moment(moment().toDate(), DATE_RANGE_FORMAT);
      let defaultStartDate = today.clone().add(-2, 'weeks');
      const defaultEndDate = today;
      if (
        oldestActivityDate &&
        moment().clone().diff(oldestActivityDate, 'weeks') < 2
      ) {
        defaultStartDate = moment(oldestActivityDate);
      }
      setRangeFilter([defaultStartDate, defaultEndDate]);
    }
  };

  useEffect(initializeRangeFilter, [
    getOldestActivityLoggedDateResponse.response,
  ]);

  const generateRequestObjectDashboardAPI = (): IDashboardGraphDataRequest | null => {
    if (rangeFilter && selectedSchool)
      return {
        className: classFilter.join(','),
        year: yearFilter.join(','),
        gender: genderFilter.join(','),
        SEND: SENDFilter.join(','),
        ethnicity: ethnicityFilter.join(','),
        start_date: rangeFilter[0].format(ISO_FORMAT),
        end_date: rangeFilter[1].format(ISO_FORMAT),
        school: selectedSchool as string,
        otherGroups: groupFilter.join(','),
        eal: EALFilter.join(','),
        pupil_premium: pupilPremiumFilter.join(','),
      };
    return null;
  };

  useEffect(() => {
    let req = generateRequestObjectDashboardAPI();
    if (cancelToken.current) {
      cancelToken.current.cancel(CANCEL_API_MSG);
    }
    cancelToken.current = axios.default.CancelToken.source();
    if (req) {
      req = { ...req, cancelToken: cancelToken.current };
      throttledGetDashboardGraphApi(req);
      throttledGetSummaryData(req);
    }
  }, [
    rangeFilter,
    classFilter,
    genderFilter,
    SENDFilter,
    ethnicityFilter,
    yearFilter,
    groupFilter,
    EALFilter,
    pupilPremiumFilter,
  ]);

  const exportData = async () => {
    const req = generateRequestObjectDashboardAPI();
    if (req) {
      // response axios blob response with header and data
      const resp = await DashboardApi.exportDashboardGraphData(req);
      const { prefix, extension } = parseContentDisposition(resp);
      const fileName = generateFileNameDate(prefix, extension);
      downloadFileFromResponse(resp.data, fileName);
    }
  };

  const updateRangeFilter = (data: [Moment, Moment]) => {
    setRangeFilter(data);
  };

  const updateYearFilter = (data: string | string[]) => {
    typeof data === 'string' ? setYearFilter([data]) : setYearFilter(data);
  };

  const updateClassFilter = (data: string | string[]) => {
    typeof data === 'string' ? setClassFilter([data]) : setClassFilter(data);
  };

  const updateGroupFilter = (data: string | string[]) => {
    typeof data === 'string' ? setGroupFilter([data]) : setGroupFilter(data);
  };

  const updateGenderFilter = (data: string | string[]) => {
    typeof data === 'string' ? setGenderFilter([data]) : setGenderFilter(data);
  };

  const updateEthinicityFilter = (data: string | string[]) => {
    typeof data === 'string'
      ? setEthnicityFilter([data])
      : setEthnicityFilter(data);
  };

  const updateSENDFilter = (data: string | string[]) => {
    typeof data === 'string' ? setSENDFilter([data]) : setSENDFilter(data);
  };

  const updateEALFilter = (data: string) => {
    typeof data === 'string' ? setEALFilter([data]) : setEALFilter(data);
  };

  const updatePupilPremiumFilter = (data: string) => {
    typeof data === 'string'
      ? setPupilPremiumFilter([data])
      : setPupilPremiumFilter(data);
  };

  if (
    (loggedInUserIsClusterAdmin(loggedInUser) ||
      loggedInUserIsClusterManager(loggedInUser)) &&
    !currentSchool
  ) {
    return (
      <div className="text-align-center">
        <h2>{DASHBOARD_PAGE_LABELS.no_school_assigned_description}</h2>
      </div>
    );
  }

  const onSchoolReset = () => {
    if (selectedSchool !== currentSchool) {
      setSelectedSchool(currentSchool);
      setClassFilter([]);
      setGroupFilter([]);
      setYearFilter([]);
    }
  };

  const handleGraphClick = (props: HandleGraphClickProps) => {
    const req = generateRequestObjectDashboardAPI();
    historyObject.push('/graph-details', {
      type: props.type,
      filters: req,
      selectedType: props.selectedType,
    });
  };

  return (
    <div className="activity-dashboard-container">
      <div className="wrapper mx-auto">
        {dashboardHeaderData.response && (
          <ActivityHighlights data={dashboardHeaderData.response} />
        )}
        <div className="d-flex justify-content-between align-items-center">
          {oldestActivityDate && rangeFilter && (
            <GraphFilters
              currentSchool={currentSchool}
              selectedSchool={selectedSchool}
              defaultRange={rangeFilter}
              onRangeChange={updateRangeFilter}
              onClassChange={updateClassFilter}
              onGroupChange={updateGroupFilter}
              selectedClasses={classFilter}
              selectedGroups={groupFilter}
              onYearChange={updateYearFilter}
              selectedYear={yearFilter}
              onSENDChange={updateSENDFilter}
              onGenderChange={updateGenderFilter}
              onEthnicityChange={updateEthinicityFilter}
              onSchoolChange={setSelectedSchool}
              onEALChange={updateEALFilter}
              onPupilPremiumChange={updatePupilPremiumFilter}
              oldestActivityDate={oldestActivityDate}
              resetRangeFilter={initializeRangeFilter}
              onSchoolReset={onSchoolReset}
              showSchool={showSchool}
            />
          )}
          {dashboardGraphData.response && (
            <Space className="card-header small-header">
              <span>{DASHBOARD_PAGE_LABELS.headline_data_toggle_title}</span>
              <Space>
                <Switch
                  defaultChecked={false}
                  onChange={(checked) => setShowSummary(checked)}
                />
                <span>{DASHBOARD_PAGE_LABELS.summary_data_toggle_title}</span>
              </Space>
            </Space>
          )}
        </div>
        {dashboardGraphData.loading || dashboardSummaryData.loading ? (
          <div className="wh-100 d-flex justify-content-between align-items-center">
            <LoadingSpinner />
          </div>
        ) : dashboardGraphData.error || dashboardSummaryData.error ? (
          <p className="error">{DASHBOARD_PAGE_LABELS.failed_to_load}</p>
        ) : (
          dashboardGraphData.response &&
          (showSummary ? (
            <SummaryGraphContainer
              data={dashboardSummaryData.response}
              exportData={exportData}
              handleGraphClick={handleGraphClick}
            />
          ) : (
            <GraphContainer
              data={dashboardGraphData.response}
              handleGraphClick={handleGraphClick}
            />
          ))
        )}
      </div>
    </div>
  );
};

export function mapStateToProps({
  loggedInUser,
  currentSchool,
}: StoreState): GraphDashboardProps {
  return {
    currentSchool,
    loggedInUser,
  };
}

export default connect(mapStateToProps)(ActivityDashboard);
