import { AppRoot, ModalRenderer } from '@dabapps/roe';
import SchoolSwitcher from '^/app/authenticated/school-switcher';
import { LoggedInUser } from '^/common/authentication/types';
import NavFooter from '^/common/nav-footer';
import { push } from 'connected-react-router';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

import Authenticated from '^/app/authenticated';
import { hasTemporaryPassword } from '^/app/authenticated/users/user-management/utils';
import Unauthenticated from '^/app/unauthenticated';
import {
  loggedInUserIsAdmin,
  loggedInUserIsClusterManager,
} from '^/common/utils/roles';
import { StoreState } from '^/store/types';
import './app.less';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID, msalConfig } from '^/common/constants';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

const msalInstance = new PublicClientApplication(msalConfig);

interface StateProps {
  isAuthenticated: boolean;
  user: LoggedInUser | null;
  modals: ReadonlyArray<React.ReactNode>;
}

interface DispatchProps {
  push: typeof push;
}

type Props = RouteComponentProps & StateProps & DispatchProps;

export class App extends PureComponent<Props> {
  public render() {
    const { isAuthenticated, user, modals } = this.props;

    const showSchoolSwitcher = Boolean(
      isAuthenticated &&
        (loggedInUserIsAdmin(user) || loggedInUserIsClusterManager(user)) &&
        !hasTemporaryPassword(user)
    );

    return (
      <AppRoot>
        <MsalProvider instance={msalInstance}>
          <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
            {showSchoolSwitcher && <SchoolSwitcher />}

            <Switch>
              <Route
                component={isAuthenticated ? Authenticated : Unauthenticated}
              />
            </Switch>

            <ToastContainer
              position={toast.POSITION.TOP_CENTER}
              hideProgressBar
            />
            <ModalRenderer modals={[...modals]} />
            <NavFooter />
          </GoogleOAuthProvider>
        </MsalProvider>
      </AppRoot>
    );
  }
}

function mapStateToProps({ loggedInUser, modals }: StoreState) {
  return {
    isAuthenticated: Boolean(loggedInUser),
    user: loggedInUser,
    modals,
  };
}

export default withRouter(
  connect(mapStateToProps, {
    push,
  })(App)
);
