import { Container, ContentBox } from '@dabapps/roe';
import { Button, Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';
import moment from 'antd/node_modules/moment';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ActivityApi from '^/api-services/activities/activities.service';
import { IRecurringActivity } from '^/api-services/activities/types';
import useAPI from '^/api-services/useApi';
import {
  ActivitiesNames,
  ActivityLocationNames,
} from '^/common/activities-constants';
import { LoggedInUser } from '^/common/authentication/types';
import {
  DATE_RANGE_FORMAT,
  MOMENT_DATE_FORMAT,
  PAGE_SIZE,
} from '^/common/constants';
import { RECURRING_ACTIVITIES_LABELS } from '^/common/labels-english';
import { loggedInUserIsTeacher } from '^/common/utils/roles';
import { StoreState } from '^/store/types';

export type StateProps = Pick<StoreState, 'currentSchool'> & {
  loggedInUser: LoggedInUser | null;
};

type Props = StateProps & {
  currentSchool: string | null;
};

const RecurringActivities: React.FC<Props> = (props: Props) => {
  const {
    callAPI: getRecurringActivities,
    ...recurringActivitiesResponse
  } = useAPI(ActivityApi.getRecurringActivities);
  const {
    callAPI: deleteRecurringActivity,
    ...deleteRecurringActivityResponse
  } = useAPI(ActivityApi.deleteRecurringActivity);

  const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: PAGE_SIZE,
  });

  const [order, setOrder] = useState('-created');

  useEffect(() => {
    fetchActivities();
  }, [props.currentSchool, order, pageInfo]);

  useEffect(() => {
    if (deleteRecurringActivityResponse.response) {
      toast.success(RECURRING_ACTIVITIES_LABELS.deleteSuccess);
      fetchActivities();
    }
  }, [deleteRecurringActivityResponse.response]);

  const fetchActivities = () => {
    if (props.currentSchool) {
      getRecurringActivities({
        school: props.currentSchool,
        page: pageInfo.page,
        page_size: pageInfo.pageSize,
        ordering: order,
      });
    }
  };

  const columnLabels = RECURRING_ACTIVITIES_LABELS.columns;

  const getColumns = useMemo(() => {
    const columns: ColumnsType<IRecurringActivity> = [
      {
        title: columnLabels.activity,
        dataIndex: 'activity',
        sorter: true,
        render: (value: string, row: IRecurringActivity) =>
          value === 'OTHER'
            ? 'Other - ' + row.other_activity_type
            : ActivitiesNames.find((activity) => activity.type === value)
                ?.label,
      },
      {
        title: columnLabels.duration,
        dataIndex: 'duration_minutes',
        sorter: true,
        render: (value) => value + ' mins',
      },
      {
        title: columnLabels.location,
        dataIndex: 'location',
        sorter: true,
        render: (value: string) =>
          ActivityLocationNames.find((location) => location.type === value)
            ?.label,
      },
      {
        title: columnLabels.studentsCount,
        dataIndex: 'students',
        sorter: true,
        render: (value) => value.length,
      },
      {
        title: columnLabels.recurringDates,
        dataIndex: 'recurring_dates',
        sorter: true,
        render: (value) => {
          const formatted = value.map((date: string) =>
            moment(date).format(DATE_RANGE_FORMAT)
          );
          return formatted.join(', ');
        },
      },
      {
        title: columnLabels.createdBy,
        dataIndex: 'user',
        sorter: true,
        defaultSortOrder: 'descend',
        render: (user, row: IRecurringActivity) => (
          <>
            <p className="margin-none">
              {user.first_name + ' ' + user.last_name}
            </p>
            <p className="margin-none font-color-grey-light">
              {moment(row.created).format(MOMENT_DATE_FORMAT)}
            </p>
          </>
        ),
      },
    ];

    if (loggedInUserIsTeacher(props.loggedInUser)) {
      columns.push({
        title: columnLabels.actions,
        render: (_: undefined, row: IRecurringActivity) => {
          if (row.user.id === props.loggedInUser?.id) {
            return (
              <Button
                onClick={() => {
                  deleteRecurringActivity(row.id);
                }}
                className="button pill"
              >
                {RECURRING_ACTIVITIES_LABELS.deleteBtn}
              </Button>
            );
          }
          return null;
        },
      });
    }
    return columns;
  }, [props.loggedInUser, deleteRecurringActivityResponse.loading]);

  const changePage = (page: number, pageSize?: number) => {
    setPageInfo({
      page: page,
      pageSize: pageSize ? pageSize : pageInfo.pageSize,
    });
  };

  const onTableChange = (sorter: SorterResult<IRecurringActivity>) => {
    let ordering = sorter.field;
    if (sorter.field === 'user') {
      ordering = 'created';
    } else if (sorter.field === 'students') {
      ordering = 'num_students';
    }
    if (sorter.order === 'descend') {
      ordering = '-' + ordering;
    }
    setOrder(ordering as string);
  };

  return (
    <Container className="margin-top-large">
      <ContentBox>
        <Pagination
          current={pageInfo.page}
          total={recurringActivitiesResponse.response?.count}
          pageSize={pageInfo.pageSize}
          onChange={changePage}
          showTotal={(total, range) =>
            `${range[0]}-${range[1]} of ${total} items`
          }
          showSizeChanger
          disabled={recurringActivitiesResponse.loading}
        />
        <Table
          rowKey="id"
          columns={getColumns}
          dataSource={recurringActivitiesResponse.response?.results || []}
          pagination={false}
          onChange={(_pagination, _filters, sorter) => {
            onTableChange(sorter as SorterResult<IRecurringActivity>);
          }}
          loading={recurringActivitiesResponse.loading}
        />
      </ContentBox>
    </Container>
  );
};

export function mapStateToProps({
  loggedInUser,
  currentSchool,
}: StoreState): StateProps {
  return {
    loggedInUser,
    currentSchool,
  };
}

export default connect(mapStateToProps)(RecurringActivities);
