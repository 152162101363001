import React, { useEffect, useState, useMemo } from 'react';
import './school.styles.less';
import { connect } from 'react-redux';
import { Button, Pagination, Table, Space, Layout, Typography } from 'antd';
import moment from 'antd/node_modules/moment';
import { ColumnsType } from 'antd/lib/table';
import useAPI from '^/api-services/useApi';
import SchoolsApi from '^/api-services/schools/schools.service';
import {
  ISchool,
  ISchoolsRequest,
  ISchoolsResponse,
} from '^/api-services/schools/types';
import { PAGE_SIZE, DATE_RANGE_FORMAT } from '^/common/constants';
import { SCHOOLS_LABELS } from '^/common/labels-english';
import { StoreState } from '^/store/types';
import { Container, ContentBox } from '@dabapps/roe';
import { SchoolModal } from '^/app/pages/schools/school-modal';

const { Title } = Typography;
const { Header } = Layout;
interface StateProps {
  currentSchool: string | null;
}

type Props = StateProps;

const SchoolList: React.FC<Props> = (props: Props) => {
  const { currentSchool } = props;
  const { callAPI: fireGetSchoolsApi, ...getSchoolsResponse } = useAPI<
    ISchoolsRequest,
    ISchoolsResponse
  >(SchoolsApi.fetchSchoolsPageData);
  const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: PAGE_SIZE,
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [schoolSelected, setSchoolSelected] = useState('');
  const [refresh, setRefresh] = useState(false);
  useEffect(() => {
    if (currentSchool || refresh) {
      fireGetSchoolsApi({
        page: pageInfo.page,
        page_size: pageInfo.pageSize,
      });
      setRefresh(false);
    }
  }, [pageInfo, refresh]);

  const changePage = (page: number, pageSize?: number) => {
    setPageInfo({
      page: page,
      pageSize: pageSize ? pageSize : pageInfo.pageSize,
    });
  };

  const { button } = SCHOOLS_LABELS;
  const action = (school: ISchool) => {
    return (
      <Space>
        <Button
          type="primary"
          shape="round"
          onClick={() => {
            setSchoolSelected(school.id as string);
            setModalOpen(true);
          }}
          className="pill"
        >
          {button.viewOrEdit}
        </Button>
        <a href={`/api/schools/activity-export/?school=${school.id}`}>
          <Button type="primary" shape="round" className="pill">
            {button.export}
          </Button>
        </a>
      </Space>
    );
  };

  const columnLabel = SCHOOLS_LABELS.column;
  const getColumns = useMemo(() => {
    const columns: ColumnsType<ISchool> = [
      {
        title: columnLabel.schoolName,
        dataIndex: 'name',
      },
      {
        title: columnLabel.location,
        dataIndex: 'location',
      },
      {
        title: columnLabel.created,
        dataIndex: 'created',
        render: (value: string) => moment(value).format(DATE_RANGE_FORMAT),
      },
      {
        title: columnLabel.action,
        dataIndex: 'action',
        render: (_: undefined, row: ISchool) => action(row),
      },
    ];
    return columns;
  }, []);

  return (
    <Layout className="school-management-container">
      <Header className="title-container">
        <Title level={3}>{SCHOOLS_LABELS.rootTableTitle}</Title>
        <Button
          type="primary"
          shape="round"
          onClick={() => {
            setSchoolSelected('');
            setModalOpen(true);
          }}
          className="pill"
        >
          {button.addSchool}
        </Button>
      </Header>
      <Container>
        <ContentBox>
          <Pagination
            current={pageInfo.page}
            total={getSchoolsResponse.response?.count}
            pageSize={pageInfo.pageSize}
            onChange={changePage}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
            showSizeChanger
            disabled={getSchoolsResponse.loading}
          />
          <Table
            rowKey="id"
            columns={getColumns}
            dataSource={getSchoolsResponse.response?.results || []}
            pagination={false}
            loading={getSchoolsResponse.loading}
          />
        </ContentBox>
      </Container>
      {isModalOpen && (
        <SchoolModal
          id={schoolSelected}
          onClose={(refresh?: boolean) => {
            setModalOpen(false);
            if (refresh) {
              setRefresh(true);
            }
          }}
        />
      )}
    </Layout>
  );
};

export function mapStateToProps(state: StoreState): StateProps {
  return {
    currentSchool: state.currentSchool,
  };
}

export default connect(mapStateToProps)(SchoolList);
