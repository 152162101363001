import { Dict } from '@dabapps/redux-api-collections/dist/utils';
import { GET_ACTIVITIES_SUMMARY } from '^/app/authenticated/activities/activity-page/actions';
import { ActivityPageActivitiesSummary } from '^/app/authenticated/activities/activity-page/types';
import { AnyAction } from 'redux';

export type ActivitiesSummaryStore = Dict<ActivityPageActivitiesSummary | null>;

export function activityPageActivitiesSummary(
  state: ActivitiesSummaryStore = {},
  action: AnyAction
): ActivitiesSummaryStore {
  switch (action.type) {
    case GET_ACTIVITIES_SUMMARY.SUCCESS:
      return {
        ...state,
        [action.meta.tag]: action.payload.data
          ? ActivityPageActivitiesSummary(action.payload.data)
          : null,
      };
    default:
      return state;
  }
}
