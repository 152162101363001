import {
  GET_COLLECTION,
  getCollectionByName,
} from '@dabapps/redux-api-collections';
import { hasFailed, isPending } from '@dabapps/redux-requests';
import { Button, Container, ContentBox } from '@dabapps/roe';
import { Pagination, Space, Table } from 'antd';
import { ColumnsType, SorterResult } from 'antd/lib/table/interface';
import classNames from 'classnames';
import { push } from 'connected-react-router';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import DashboardStudentListFilters from '^/app/authenticated/activities/overview/students/filters';
import { transformFilterOptionsToCollectionFilter } from '^/app/authenticated/activities/overview/students/filters/utils';
import {
  DashboardStudentListLatestActivity,
  DashboardStudentListUser,
} from '^/app/authenticated/activities/overview/students/types';
import AssessmentModal from '^/app/authenticated/assessments/assessment-modal';
import { Status } from '^/app/authenticated/assessments/types';
import MessageModal from '^/app/authenticated/messaging/message-modal';
import { ConfirmButton } from '^/app/components';
import { LoggedInUser, UserRoles } from '^/common/authentication/types';
import { collections } from '^/common/collections';
import { ACTIVITY_NAMES, PAGE_SIZE } from '^/common/constants';
import ErrorMessage from '^/common/error-handling/error-message';
import {
  ACTIVITIES_LABELS,
  ACTIVITIES_TABLE_COLUMNS,
  MESSAGE_PAGE_LABELS,
  TABLE_EMPTY_CELL,
} from '^/common/labels-english';
import { openModal } from '^/common/modals/actions';
import { PermissionRequired } from '^/common/permissions';
import StatusPill from '^/common/status-pill';
import { formatDuration } from '^/common/utils/durations';
import { formatDate } from '^/common/utils/format-date';
import { getPageFromQueryString } from '^/common/utils/get-page-from-query-string';
import { loggedInUserIsTeacher } from '^/common/utils/roles';
import { StoreState } from '^/store/types';
import { AddActivityModal } from '../../../../../pages/activities/add-activity-modal';

import {
  STUDENT_DASHBOARD,
  STUDENTS_COLLECTION,
  STUDENTS_DEFAULT_SORT,
} from './constants';

const {
  actions: { getCollection },
} = collections;

interface StateProps
  extends Pick<StoreState, 'currentSchool'>,
    Pick<StoreState, 'selectedDashboardFilters'> {
  count: number;
  page: number;
  isLoading: boolean;
  requestHasFailed: boolean;
  students: DashboardStudentListUser[];
  loggedInUser: LoggedInUser | null;
}

interface DispatchProps {
  getCollection: typeof getCollection;
  push: typeof push;
  openModal: typeof openModal;
}

export type Props = StateProps & DispatchProps;

const StudentList: React.FC<Props> = (props: Props) => {
  const [pageInfo, setPageInfo] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: PAGE_SIZE,
  });
  const { TEACHER } = UserRoles;
  const [isAddActivityOpen, setAddActivityOpen] = useState(false);
  const fetchStudents = (sorter?: string) => {
    const { currentSchool, selectedDashboardFilters } = props;
    if (currentSchool) {
      const filters = transformFilterOptionsToCollectionFilter(
        selectedDashboardFilters
      );
      const { searchQuery } = selectedDashboardFilters;
      props.getCollection(
        STUDENTS_COLLECTION,
        {
          ...pageInfo,
          filters: { ...filters, school: currentSchool },
          search: searchQuery,
          ordering: sorter ? sorter : STUDENTS_DEFAULT_SORT,
        },
        STUDENT_DASHBOARD
      );
    }
  };

  const [order, setOrder] = useState<string>(STUDENTS_DEFAULT_SORT);

  useEffect(() => {
    fetchStudents(order);
  }, [pageInfo]);

  const changePage = (page: number, pageSize?: number) => {
    setPageInfo({
      page: page,
      pageSize: pageSize ? pageSize : pageInfo.pageSize,
    });
  };

  const openAssessmentModal = (studentId: string, studentName: string) => {
    props.openModal(
      <AssessmentModal studentId={studentId} studentName={studentName} />
    );
  };

  const openMessagingModal = (isAllStudents = false, count = 0) => {
    props.openModal(
      <MessageModal
        studentName={getStudentName()}
        studentIds={selectedStudents}
        isAllStudents={isAllStudents}
        count={count}
      />
    );
  };

  const getStudentName = () => {
    const { students } = props;
    if (selectedStudents.length === 1) {
      // todo: if the selected student is another page , name cant be found
      const selectedStudent = students.find(
        (student) => student.id === selectedStudents[0]
      );
      return (
        selectedStudent &&
        `${selectedStudent.first_name} ${selectedStudent.last_name}`
      );
    }
  };

  const { isLoading, requestHasFailed, count, students, loggedInUser } = props;

  const nameColumn = (student: DashboardStudentListUser) => {
    return (
      <>
        <Link
          to={{
            pathname: `/activities/${student.id}`,
          }}
          className="font-weight-bold primary margin-none"
        >
          {student.first_name} {student.last_name}
        </Link>
        <p className="font-color-grey-light margin-none">
          {student.class_group_name}
        </p>
      </>
    );
  };

  const activityColumn = (activity: DashboardStudentListLatestActivity) => {
    return activity ? (
      <>
        <p className="margin-none">{ACTIVITY_NAMES[activity.activity]}</p>
        <p className="margin-none font-color-grey-light">
          {formatDate(activity.date)}
        </p>
      </>
    ) : (
      TABLE_EMPTY_CELL
    );
  };

  const statusColumn = (status: Status) =>
    status ? <StatusPill status={status} /> : TABLE_EMPTY_CELL;

  const actions = (student: DashboardStudentListUser) => {
    return (
      <Space>
        <Link
          className="button pill hollow"
          to={{
            pathname: `/activities/${student.id}`,
          }}
        >
          View
        </Link>
        {isTeacher && (
          <PermissionRequired hideIfNoPermission requiredRoles={[TEACHER]}>
            <Button
              onClick={() =>
                openAssessmentModal(
                  student.id,
                  student.first_name + ' ' + student.last_name
                )
              }
              className="pill"
            >
              Assess
            </Button>
          </PermissionRequired>
        )}
      </Space>
    );
  };

  const columns: ColumnsType<DashboardStudentListUser> = [
    {
      title: ACTIVITIES_TABLE_COLUMNS.studentName,
      dataIndex: 'name',
      sorter: true,
      render: (_: undefined, row: DashboardStudentListUser) => nameColumn(row),
    },

    {
      title: ACTIVITIES_TABLE_COLUMNS.lastActivity,
      dataIndex: 'latest_activity',
      sorter: true,
      defaultSortOrder: 'descend',
      render: activityColumn,
    },
    {
      title: ACTIVITIES_TABLE_COLUMNS.activitiesCount,
      dataIndex: 'activity_count',
      sorter: true,
    },
    {
      title: ACTIVITIES_TABLE_COLUMNS.pendingActivitiesCount,
      dataIndex: 'pending_activities_count',
      sorter: true,
    },
    {
      title: ACTIVITIES_TABLE_COLUMNS.totalActivityMins,
      dataIndex: 'total_activity_minutes',
      sorter: true,
      render: (minutes: number) => formatDuration(minutes),
    },
    {
      title: ACTIVITIES_TABLE_COLUMNS.lastAssessmentStatus,
      dataIndex: 'latest_assessment_status',
      sorter: true,
      render: statusColumn,
    },
    {
      title: ACTIVITIES_TABLE_COLUMNS.actions,
      render: (_: undefined, row: DashboardStudentListUser) => actions(row),
    },
  ];

  const isTeacher = loggedInUserIsTeacher(loggedInUser);
  const [selectedStudents, setSelectedStudents] = useState<string[]>([]);

  const onTableChange = (sorter: SorterResult<DashboardStudentListUser>) => {
    let ordering = sorter.field;
    if (sorter.field === 'name') {
      ordering = 'first_name,last_name';
    } else if (sorter.field === 'latest_assessment_status') {
      ordering = 'latest_assessment__status';
    } else if (sorter.field === 'latest_activity') {
      ordering = 'latest_activity__date';
    } else if (sorter.field === 'year_group_name') {
      ordering = 'year_group__name';
    }
    if (sorter.order === 'descend') {
      ordering = '-' + ordering;
    }
    if (sorter.order === 'descend' && sorter.field === 'name') {
      ordering = '-first_name,-last_name';
    }
    setOrder(ordering as string);
    fetchStudents(ordering as string);
  };
  return (
    <Container className="margin-top-large">
      <DashboardStudentListFilters changePage={changePage} />

      {requestHasFailed ? (
        <ContentBox className="padding-vertical-large">
          <ErrorMessage />
        </ContentBox>
      ) : (
        <ContentBox
          className={classNames(
            'student-list p-0',
            count > 0 ? 'has-results' : 'empty',
            isTeacher ? 'is-teacher' : 'is-admin'
          )}
        >
          <div className="d-flex align-items-center justify-content-between px-3">
            <Pagination
              current={pageInfo.page}
              total={count}
              pageSize={pageInfo.pageSize}
              onChange={changePage}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showSizeChanger
              disabled={isLoading}
              showLessItems
            />
            {isTeacher && Boolean(students.length) && (
              <PermissionRequired hideIfNoPermission requiredRoles={[TEACHER]}>
                <Space>
                  <Button
                    type="button"
                    onClick={() => setSelectedStudents([])}
                    className="pill message-button"
                    disabled={isLoading || !selectedStudents.length}
                  >
                    {ACTIVITIES_LABELS.resetSelectionBtn}
                  </Button>
                  <Button
                    type="button"
                    onClick={() => setAddActivityOpen(true)}
                    className="pill message-button"
                    disabled={isLoading || !selectedStudents.length}
                  >
                    {ACTIVITIES_LABELS.addActivityBtn}
                  </Button>
                  {!selectedStudents.length ? (
                    <ConfirmButton
                      onConfirm={() => openMessagingModal(false, count)}
                      buttonTitle={MESSAGE_PAGE_LABELS.buttonTitle}
                      buttonClassName="button non-antd-button pill message-button"
                      popConfirmMessages={{
                        success: MESSAGE_PAGE_LABELS.messageAllPopupSuccess,
                        failure: MESSAGE_PAGE_LABELS.messageAllPopupFailure,
                        title: MESSAGE_PAGE_LABELS.messageAllPopupTitle(count),
                      }}
                      placement="bottomLeft"
                      disabled={isLoading}
                    />
                  ) : (
                    <Button
                      type="button"
                      onClick={() => openMessagingModal(false)}
                      className="pill message-button"
                      disabled={isLoading}
                    >
                      {MESSAGE_PAGE_LABELS.buttonTitle}
                    </Button>
                  )}
                </Space>
              </PermissionRequired>
            )}
          </div>
          {isTeacher ? (
            <>
              <Table
                rowKey="id"
                columns={columns}
                dataSource={students}
                rowSelection={{
                  selectedRowKeys: selectedStudents,
                  onChange: (selected: React.ReactText[]) => {
                    setSelectedStudents(selected as string[]);
                  },
                  preserveSelectedRowKeys: true,
                }}
                pagination={false}
                onChange={(_pagination, _filters, sorter) => {
                  onTableChange(
                    sorter as SorterResult<DashboardStudentListUser>
                  );
                }}
                loading={isLoading}
              />
              {isAddActivityOpen && (
                <AddActivityModal
                  studentIds={selectedStudents}
                  onClose={() => {
                    setAddActivityOpen(false);
                  }}
                />
              )}
            </>
          ) : (
            <Table
              rowKey="id"
              columns={columns}
              dataSource={students}
              pagination={false}
              onChange={(_pagination, _filters, sorter) => {
                onTableChange(sorter as SorterResult<DashboardStudentListUser>);
              }}
              loading={isLoading}
            />
          )}
        </ContentBox>
      )}
    </Container>
  );
};

export function mapStateToProps(
  state: StoreState,
  props: RouteComponentProps
): StateProps {
  const { currentSchool, responses, selectedDashboardFilters } = state;
  const studentsCollection = getCollectionByName(
    state.collections,
    STUDENTS_COLLECTION,
    STUDENT_DASHBOARD
  );
  const students = studentsCollection.results;
  return {
    currentSchool,
    selectedDashboardFilters,
    count: studentsCollection.count,
    page: getPageFromQueryString(props),
    isLoading: isPending(responses, GET_COLLECTION, STUDENTS_COLLECTION),
    requestHasFailed: hasFailed(responses, GET_COLLECTION, STUDENTS_COLLECTION),
    students: students as DashboardStudentListUser[],
    loggedInUser: state.loggedInUser,
  };
}

export default withRouter(
  connect(mapStateToProps, { getCollection, push, openModal })(StudentList)
);
