import { responsesReducer } from '@dabapps/redux-requests';
import {
  connectRouter,
  routerMiddleware,
  RouterState,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import * as Cookies from 'js-cookie';
import {
  AnyAction,
  applyMiddleware,
  combineReducers,
  createStore,
  Dispatch,
  Middleware,
  MiddlewareAPI,
  Reducer,
} from 'redux';
import { reducer as formReducer } from 'redux-form';
import thunk from 'redux-thunk';
import * as _ from 'underscore';

import { activityPageActivitiesSummary } from '^/app/authenticated/activities/activity-page/reducers';
import {
  activityHighlightStats,
  availableStatsPeriods,
  currentStatsPeriod,
} from '^/app/authenticated/activities/overview/stats/reducers';
import * as dashboardFilterReducers from '^/app/authenticated/activities/overview/students/filters/reducers';
import * as assessmentModalReducers from '^/app/authenticated/assessments/reducers';
import * as schoolSwitcherReducers from '^/app/authenticated/school-switcher/reducers';
import * as importUsersReducers from '^/app/authenticated/users/import-users/reducers';
import authenticationMiddleware from '^/common/authentication/middleware';
import * as authenticationReducers from '^/common/authentication/reducers';
import { LoggedInUser } from '^/common/authentication/types';
import { collections } from '^/common/collections';
import { CURRENT_SCHOOL_COOKIE_KEY } from '^/common/constants';
import errorHandlingMiddleware from '^/common/error-handling/middleware';
import { modals } from '^/common/modals/reducers';
import * as schoolsReducers from '^/common/schools/reducers';
import { ThunkActionOrAction } from '^/common/types';
import changePasswordMiddleware from '^/common/users/middleware';
import { StoreState } from '^/store/types';

export const historyObject = createBrowserHistory();

const loggedInUser =
  window.INITIAL_USER_DATA && !_.isEmpty(window.INITIAL_USER_DATA)
    ? LoggedInUser(window.INITIAL_USER_DATA)
    : null;

const loggedInUserSchool = loggedInUser ? loggedInUser.school : null;
const cookieSchool = Cookies.get(CURRENT_SCHOOL_COOKIE_KEY) || null;

const INITIAL_STATE: Partial<StoreState> = {
  loggedInUser,
  currentSchool: loggedInUserSchool || cookieSchool,
};

const rootReducer = combineReducers<StoreState>({
  router: connectRouter(historyObject) as Reducer<RouterState, AnyAction>,
  responses: responsesReducer,
  collections: collections.reducers.collectionsReducer,
  items: collections.reducers.itemsReducer,
  form: formReducer,
  activityPageActivitiesSummary,
  activityHighlightStats,
  availableStatsPeriods,
  currentStatsPeriod,
  modals,
  assessmentModal: combineReducers({ ...assessmentModalReducers }),
  ...importUsersReducers,
  ...authenticationReducers,
  ...schoolSwitcherReducers,
  ...schoolsReducers,
  ...dashboardFilterReducers,
});

const middleware: Array<Middleware<{}, StoreState>> = [
  thunk,
  routerMiddleware(historyObject),
  <S>(api: MiddlewareAPI<Dispatch, S>) => (next: Dispatch) => (
    action: ThunkActionOrAction
  ) =>
    typeof action === 'function'
      ? next(action(api.dispatch, api.getState, undefined))
      : next(action),
  authenticationMiddleware,
  errorHandlingMiddleware,
  changePasswordMiddleware,
];

const store = createStore<StoreState, AnyAction, {}, undefined>(
  rootReducer,
  INITIAL_STATE,
  applyMiddleware(...middleware)
);

export default store;
