import { hasSucceeded } from '@dabapps/redux-requests';
import { LOCATION_CHANGE, replace } from 'connected-react-router';
import { AnyAction, Dispatch, MiddlewareAPI } from 'redux';

import {
  getChangePasswordPath,
  hasTemporaryPassword,
} from '^/app/authenticated/users/user-management/utils';
import { CHANGE_PASSWORD } from '^/common/authentication/actions';
import { StoreState } from '^/store/types';

function isNavigatingAwayFromChangePassword(action: AnyAction, userId: string) {
  return Boolean(
    action.type === LOCATION_CHANGE &&
      action.payload.location.pathname !== getChangePasswordPath(userId)
  );
}

export function shouldNavigateToChangePassword(
  action: AnyAction,
  state: StoreState
) {
  const loggedInUser = state.loggedInUser;
  return Boolean(
    !hasSucceeded(state.responses, CHANGE_PASSWORD) &&
      hasTemporaryPassword(loggedInUser) &&
      (loggedInUser &&
        isNavigatingAwayFromChangePassword(action, loggedInUser.id))
  );
}

export default (store: MiddlewareAPI<Dispatch<AnyAction>, StoreState>) => (
  next: Dispatch
) => (action: AnyAction) => {
  const storeState = store.getState();

  if (
    shouldNavigateToChangePassword(action, storeState) &&
    storeState.loggedInUser
  ) {
    return next(replace(getChangePasswordPath(storeState.loggedInUser.id)));
  }

  return next(action);
};
