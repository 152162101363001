import { GET_LOGGED_IN_USER, LOGOUT } from '^/common/authentication/actions';
import { LoggedInUser } from '^/common/authentication/types';
import { AnyAction } from 'redux';

export function loggedInUser(
  state: LoggedInUser | null = null,
  action: AnyAction
): LoggedInUser | null {
  switch (action.type) {
    case LOGOUT.SUCCESS:
      return null;
    case GET_LOGGED_IN_USER.SUCCESS:
      return LoggedInUser(action.payload.data);
    default:
      return state;
  }
}
