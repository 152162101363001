import { Column, Container, ContentBox, Row } from '@dabapps/roe';
import { ColumnsType } from 'antd/lib/table';
import { Checkbox, Radio, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { LoggedInUser } from '^/common/authentication/types';
import { SCHOOL_SETTINGS_LABELS } from '^/common/labels-english';
import { StoreState } from '^/store/types';
import '../../app.less';
// import useAPI from '^/api-services/useApi';
// import ProfileApi from '^/api-services/profile/profile.service';
import { collections } from '^/common/collections';
import { editSocialSettings } from './actions';
import {
  getCollectionByName,
  GET_COLLECTION,
} from '@dabapps/redux-api-collections';
import { hasFailed, isPending } from '@dabapps/redux-requests';
import { SchoolSettingsList } from './types';
import './school.styles.less';

const {
  actions: { getCollection, getItem },
} = collections;

export type StateProps = Pick<StoreState, 'currentSchool'> & {
  loggedInUser: LoggedInUser | null;
  currentSchool: string | null;
  schoolSettingsResponse: Array<SchoolSettingsList>;
  hasFailedRequest: boolean;
  isLoading: boolean;
};
interface DispatchProps {
  getCollection: typeof getCollection;
  getItem: typeof getItem;
  editSocialSettings: typeof editSocialSettings;
}

type Props = DispatchProps & StateProps;

const SchoolSettings: React.FC<Props> = (props: Props) => {
  // const {
  //   callAPI: getSchoolSettingsList,
  //   ...schoolSettingsResponse
  // } = useAPI(ProfileApi.getSocialSettingsList);

  // const fetchSchoolSettings = () => {
  //   if (props.currentSchool) {
  //     // getSchoolSettingsList({});
  //     props.editSocialSettings(
  //       {
  //         "id": "27721d0b-e6b2-4209-a614-f0b256f2152d",
  //         "year_group": "Year 4",
  //         "own_class": false,
  //         "own_year": false,
  //         "own_groups": true,
  //         "all": true,
  //       }
  //     )
  //   }
  // };

  // useEffect(() => {
  //   fetchSchoolSettings();
  // }, []);

  useEffect(() => {
    fetchSettings();
  }, []);

  const columnLabels = SCHOOL_SETTINGS_LABELS.columns;
  const [state, setState] = useState<Array<SchoolSettingsList>>([]);

  useEffect(() => {
    setState(props.schoolSettingsResponse);
  }, [props.schoolSettingsResponse]);

  const fetchSettings = () => {
    props.getCollection('profile/social-settings-list');
  };

  const handleEdit = (row: SchoolSettingsList) => {
    props.editSocialSettings(row);
  };

  const getColumns = useMemo(() => {
    const columns: ColumnsType<any> = [
      {
        title: columnLabels.year,
        dataIndex: 'year_group',
        sorter: false,
        render: (value: string) => value,
      },
      {
        title: columnLabels.ownClass,
        dataIndex: 'own_class',
        sorter: false,
        // eslint-disable-next-line react/display-name
        render: (value: boolean, row: SchoolSettingsList) => (
          <Radio
            className="table-input"
            checked={value}
            disabled={row.all}
            onChange={() =>
              handleEdit({
                ...row,
                own_class: !row.own_class,
                own_year: row.own_class,
              })
            }
          ></Radio>
        ),
      },
      {
        title: columnLabels.ownYear,
        dataIndex: 'own_year',
        sorter: false,
        // eslint-disable-next-line react/display-name
        render: (value: boolean, row: SchoolSettingsList) => (
          <Radio
            className="table-input"
            checked={value}
            disabled={row.all}
            onChange={() =>
              handleEdit({
                ...row,
                own_year: !row.own_year,
                own_class: row.own_year,
              })
            }
          ></Radio>
        ),
      },
      {
        title: columnLabels.groups,
        dataIndex: 'own_groups',
        sorter: false,
        // eslint-disable-next-line react/display-name
        render: (value: boolean, row: SchoolSettingsList) => (
          <Checkbox
            className="table-input"
            checked={value}
            disabled={row.all}
            onChange={() =>
              handleEdit({
                ...row,
                own_groups: !row.own_groups,
              })
            }
          ></Checkbox>
        ),
      },
      {
        title: columnLabels.anyStudent,
        dataIndex: 'all',
        sorter: false,
        // eslint-disable-next-line react/display-name
        render: (value: boolean, row: SchoolSettingsList) => (
          <Checkbox
            className="table-input"
            checked={value}
            onChange={() =>
              handleEdit({
                ...row,
                all: !row.all,
              })
            }
          ></Checkbox>
        ),
      },
    ];
    return columns;
  }, []);

  return (
    <Container className="margin-top-large">
      <ContentBox>
        <p>
          Students can only interact with other students who you set as{' '}
          <b>discoverable</b>. Use the table to manage who is{' '}
          <b>discoverable</b>.
        </p>
        <p className="mb-1">
          Select <b>one</b> from Class or Year.
        </p>
        <p className="mt-1 mb-1">You can add Groups as an optional extra.</p>
        <p className="mt-1">
          Selecting <b>Any student in the school</b> overrides the other
          columns.
        </p>
        {!props.isLoading && (
          <Table
            rowKey="id"
            columns={getColumns}
            dataSource={state || []}
            pagination={false}
            // onChange={(_pagination, _filters, sorter) => {
            //   onTableChange(sorter as SorterResult<IRecurringActivity>);
            // }}
            loading={props.isLoading}
          />
        )}
        <Row className="d-flex align-items-center justify-content-start">
          <Column sm={12} md={9}>
            <p>
              Tip: As well as using existing groups you can set up groups for
              students who you think would support each other be more active
              e.g. a support group for Girls in Years 7 and 8. Go to Classes and
              Groups to create and manage Groups
            </p>
          </Column>
          {/* <Column sm={12} md={2}>
            <Button className="button pill w-100">Save</Button>
          </Column> */}
        </Row>
      </ContentBox>
    </Container>
  );
};

export function mapStateToProps(state: StoreState): StateProps {
  const schoolSettingsResponse = getCollectionByName(
    state.collections,
    'profile/social-settings-list'
  );

  const schoolSettingsResponseHasFailed = hasFailed(
    state.responses,
    GET_COLLECTION,
    'profile/social-settings-list'
  );
  return {
    loggedInUser: state.loggedInUser,
    currentSchool: state.currentSchool,
    schoolSettingsResponse: schoolSettingsResponse.results as Array<
      SchoolSettingsList
    >,
    hasFailedRequest: schoolSettingsResponseHasFailed,
    isLoading: isPending(
      state.responses,
      GET_COLLECTION,
      'profile/social-settings-list'
    ),
  };
}

export default connect(mapStateToProps, {
  getCollection,
  getItem,
  editSocialSettings,
})(SchoolSettings);
