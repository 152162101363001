import {
  hasFailed,
  hasSucceeded,
  isPending,
  resetRequestState,
} from '@dabapps/redux-requests';
import { Alert, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  RESET_PASSWORD,
  resetPassword,
  ResetPasswordData,
} from '^/common/authentication/actions';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';
import { StoreState } from '^/store/types';
import ResetPasswordForm from './reset-password-form';

interface StateProps {
  isLoading: boolean;
  hasFailed: boolean;
  hasSucceeded: boolean;
  errors?: ResponseErrors;
}

interface DispatchProps {
  resetPassword: typeof resetPassword;
  resetRequestState: typeof resetRequestState;
}

type Props = StateProps & DispatchProps;

export class ResetPasswordPage extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.resetRequestState(RESET_PASSWORD);
  }

  public render() {
    const { isLoading, errors } = this.props;
    return (
      <ContentBox>
        <ContentBoxHeader>
          <img
            className="nav-logo md-margin-vertical-base"
            alt="miMove"
            src="/static/logo.png"
          />
          <h2>Reset Password</h2>
        </ContentBoxHeader>
        {!this.props.hasSucceeded ? (
          <div>
            <p>
              Enter your email address and you will be emailed instructions on
              how to reset your password.
            </p>
            <ResetPasswordForm
              onSubmit={this.onReset}
              isLoading={isLoading}
              hasFailed={this.props.hasFailed}
              errors={errors}
            />
          </div>
        ) : (
          <div>
            <Alert type="success">
              <p>
                Your have been sent instructions on resetting your password to
                your email address if you have an account registered with us.
              </p>
            </Alert>
            <hr />
            <div className="margin-vertical-base">
              <Link to="/">Back to Login</Link>
            </div>
          </div>
        )}
      </ContentBox>
    );
  }

  private onReset = (data: ResetPasswordData) => {
    this.props.resetPassword(data);
  };
}

export function mapStateToProps({ responses }: StoreState): StateProps {
  return {
    isLoading: isPending(responses, RESET_PASSWORD),
    hasFailed: hasFailed(responses, RESET_PASSWORD),
    hasSucceeded: hasSucceeded(responses, RESET_PASSWORD),
    errors: getResponseErrors(responses, RESET_PASSWORD),
  };
}

export default connect(
  mapStateToProps,
  { resetPassword, resetRequestState }
)(ResetPasswordPage);
