import { Button, ContentBoxFooter, FormGroup } from '@dabapps/roe';
import React from 'react';
import { Link } from 'react-router-dom';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { ResetPasswordData } from '^/common/authentication/actions';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import { ResponseErrors } from '^/common/utils/errors';

export interface ExternalProps {
  isLoading: boolean;
  hasFailed: boolean;
  errors?: ResponseErrors;
}

export type FormProps = ExternalProps &
  InjectedFormProps<ResetPasswordData, ExternalProps>;

export class ResetPasswordForm extends React.PureComponent<FormProps> {
  public render() {
    const { handleSubmit, isLoading, hasFailed, errors } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormGroup block>
          <label>Email Address</label>
          <Field
            name="email"
            component="input"
            type="email"
            disabled={isLoading}
          />
          <FormErrorRenderer formErrors={errors} formErrorsKey={'email'} />
        </FormGroup>
        <hr />
        <div className="margin-vertical-small">
          <Link to="/">Back to login</Link>
        </div>
        <ContentBoxFooter>
          <FormErrorRenderer
            formErrors={errors}
            formErrorsKey={'non_field_errors'}
          />
          {hasFailed && (
            <FormErrorRenderer
              formErrors={{ error: 'Failed to Reset Password' }}
              formErrorsKey={'error'}
            />
          )}
          {isLoading ? (
            <span>Loading&hellip;</span>
          ) : (
            <Button className="pill" type="submit" disabled={isLoading}>
              Reset Password
            </Button>
          )}
        </ContentBoxFooter>
      </form>
    );
  }
}

export default reduxForm<ResetPasswordData, ExternalProps>({
  form: 'reset-password',
})(ResetPasswordForm);
