import { uniqueId } from 'underscore';
import moment from 'moment';
import { DATE_FORMAT_FOR_FILENAME } from '^/common/constants';

export const downloadFileFromResponse = (respone: any, fileName: string) => {
  const url = window.URL.createObjectURL(respone);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

// example return: prefix{number}.ext
export const generateFileNameUUID = (prefix: string, ext: string) => {
  return `${uniqueId(prefix)}.${ext.toLowerCase()}`;
};

// example return: prefix-2020-05-22.ext
export const generateFileNameDate = (prefix: string, ext: string) => {
  return `${prefix}-${moment().format(
    DATE_FORMAT_FOR_FILENAME
  )}.${ext.toLowerCase()}`;
};

export const parseContentDisposition = (
  resp: any
): { prefix: string; extension: string } => {
  const returnObj = { prefix: '', extension: '' };
  try {
    const data = resp.headers['content-disposition'];
    if (data) {
      // example of data = 'attachment; filename=mimove-activity-report.xlsx'
      const filename = (data as string).split('filename=')[1];
      const index = filename.lastIndexOf('.');
      return {
        prefix: filename.substring(0, index),
        extension: filename.substring(index + 1),
      };
    }
    return returnObj;
  } catch (error) {
    return returnObj;
  }
};
