import {
  hasFailed,
  hasSucceeded,
  isPending,
  resetRequestState,
} from '@dabapps/redux-requests';
import { Alert, ContentBox, ContentBoxHeader } from '@dabapps/roe';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Link } from 'react-router-dom';

import {
  RESET_PASSWORD_CONFIRMATION,
  resetPasswordConfirmation,
} from '^/common/authentication/actions';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';
import { StoreState } from '^/store/types';
import ResetPasswordConfirmationForm, {
  ResetPasswordConfirmationFormData,
} from './reset-password-confirmation-form';

export interface RouteParamProps {
  uid: string;
  token: string;
}

interface StateProps {
  isLoading: boolean;
  hasFailed: boolean;
  hasSucceeded: boolean;
  errors?: ResponseErrors;
}

interface DispatchProps {
  resetPasswordConfirmation: typeof resetPasswordConfirmation;
  resetRequestState: typeof resetRequestState;
}

type Props = StateProps & DispatchProps & RouteComponentProps<RouteParamProps>;

export class ResetPasswordConfirmationPage extends React.PureComponent<Props> {
  public componentDidMount() {
    this.props.resetRequestState(RESET_PASSWORD_CONFIRMATION);
  }

  public render() {
    const { isLoading, errors } = this.props;
    return (
      <ContentBox>
        <ContentBoxHeader>
          <img
            className="nav-logo md-margin-vertical-base"
            alt="miMove"
            src="/static/logo.png"
          />
          <h2>Reset Password</h2>
        </ContentBoxHeader>
        {!this.props.hasSucceeded ? (
          <ResetPasswordConfirmationForm
            onSubmit={this.onResetConfirmation}
            isLoading={isLoading}
            hasFailed={this.props.hasFailed}
            errors={errors}
          />
        ) : (
          <Alert type="success">
            <p>
              You password has been updated. You may now{' '}
              <Link to="/">login</Link> using your new password.
            </p>
          </Alert>
        )}
      </ContentBox>
    );
  }

  private onResetConfirmation = (
    formData: ResetPasswordConfirmationFormData
  ) => {
    const {
      match: {
        params: { uid, token },
      },
    } = this.props;
    const data = {
      ...formData,
      uid,
      token,
    };
    this.props.resetPasswordConfirmation(data);
  };
}

export function mapStateToProps({ responses }: StoreState): StateProps {
  return {
    isLoading: isPending(responses, RESET_PASSWORD_CONFIRMATION),
    hasFailed: hasFailed(responses, RESET_PASSWORD_CONFIRMATION),
    hasSucceeded: hasSucceeded(responses, RESET_PASSWORD_CONFIRMATION),
    errors: getResponseErrors(responses, RESET_PASSWORD_CONFIRMATION),
  };
}

export default connect(
  mapStateToProps,
  {
    resetPasswordConfirmation,
    resetRequestState,
  }
)(ResetPasswordConfirmationPage);
