import { Button, ContentBoxFooter, FormGroup } from '@dabapps/roe';
import { gapi } from 'gapi-script';
import React from 'react';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';
import SchoolSwitcher from '^/app/authenticated/school-switcher/select';

import { LoginData, LOGIN_SOCIAL } from '^/common/authentication/actions';
import FormErrorRenderer from '^/common/error-handling/form-error-renderer';
import { getResponseErrors, ResponseErrors } from '^/common/utils/errors';

import { getSchoolsList } from '^/common/authentication/actions';
import { connect } from 'react-redux';
import { BasicSchool } from '^/app/authenticated/school-switcher/types';
import { hasFailed } from '@dabapps/redux-requests';
import { StoreState } from '^/store/types';
import { loginRequest } from '^/common/constants';
import { useGoogleLogin } from '@react-oauth/google';
import { useMsal } from '@azure/msal-react';

export interface ExternalProps {
  isLoading: boolean;
  hasFailed: boolean;
  errors?: ResponseErrors;
  onLoginSocial: any;
}

export type FormProps = ExternalProps &
  InjectedFormProps<LoginData, ExternalProps>;

export class LoginForm extends React.PureComponent<FormProps> {
  state = { is_teacher: true, selectedSchool: {} };

  constructor(props: any) {
    super(props);

    this.setSelectedSchool = this.setSelectedSchool.bind(this);
    this.authHandler = this.authHandler.bind(this);
  }

  setSelectedSchool(school: BasicSchool | undefined) {
    this.props.change('school', school?.value);
    console.log(school);
    this.setState({
      ...this.state,
      selectedSchool: school ? school : {},
    });
  }

  changeUser = () => {
    this.setState({
      ...this.state,
      selectedSchool: {},
      is_teacher: !this.state.is_teacher,
    });
  };

  authHandler(err: any, data: any) {
    sessionStorage.removeItem('msal.idtoken');
    if (!err && data) {
      console.log('length', data['accessToken'].length);
      const payload = {
        school: this.state.selectedSchool.value,
        access_token: data['accessToken'] || null,
        code: 'MICROSOFT',
      };
      this.props.onLoginSocial(payload);
    }
  }

  responseGoogle = (response: any) => {
    console.log('>>>>>response', response);
    if ('error' in response) {
      console.log('google', response['error']);
    } else {
      const payload = {
        school: this.state.selectedSchool.value,
        access_token: response['access_token'] || null,
        code: 'GOOGLE',
      };
      console.log('>>>>>payload', payload);

      this.props.onLoginSocial(payload);
    }
  };
  componentDidMount() {
    // function start() {
    //   gapi.client.init({
    //     clientId: GOOGLE_CLIENT_ID,
    //     scope: 'email',
    //   });
    // }
    // gapi.load('client:auth2', start);

    getSchoolsList();
  }

  public render() {
    const { handleSubmit, isLoading, hasFailed, errors } = this.props;

    return (
      <form onSubmit={handleSubmit}>
        <FormGroup block>
          <label style={{ display: 'flex', alignItems: 'center' }}>
            <Field
              name="is_teacher"
              component="input"
              style={{ marginRight: '6px' }}
              type="radio"
              checked={this.state.is_teacher}
              onClick={() => {
                this.changeUser();
              }}
              disabled={isLoading}
            />
            I am a Teacher/Lead Teacher
          </label>

          <label
            style={{ display: 'flex', alignItems: 'center', marginTop: '6px' }}
          >
            <Field
              name="is_admin"
              component="input"
              style={{ marginRight: '6px' }}
              type="radio"
              checked={!this.state.is_teacher}
              onClick={() => {
                this.changeUser();
              }}
              disabled={isLoading}
            />
            I am an Administrator/Cluster Admin or Manager
          </label>
        </FormGroup>
        {this.state.is_teacher && (
          <>
            <FormGroup block>
              <SchoolSwitcher setSelectedSchool={this.setSelectedSchool} />
            </FormGroup>
          </>
        )}
        {/* {school?.sso !== 'MICROSOFT' && */}
        {this.state.is_teacher &&
          this.state.selectedSchool?.sso === 'MICROSOFT' && (
            <>
              <hr />
              <div className="margin-vertical-base">
                Help: Having trouble logging in. Please email{' '}
                <a href="mailto:team@mimoveapp.com">team@mimoveapp.com</a>
              </div>
              <ContentBoxFooter>
                <FormErrorRenderer
                  formErrors={errors}
                  formErrorsKey={'non_field_errors'}
                />
                {hasFailed && (
                  <FormErrorRenderer
                    formErrors={{ error: 'Failed to login' }}
                    formErrorsKey={'error'}
                  />
                )}

                <MicrosoftLogin responseMicrosoft={this.authHandler} />
              </ContentBoxFooter>
            </>
          )}
        {/* {school?.sso !== 'GOOGLE' && */}
        {this.state.is_teacher && this.state.selectedSchool?.sso === 'GOOGLE' && (
          <>
            <hr />
            <div className="margin-vertical-base">
              Help: Having trouble logging in. Please email{' '}
              <a href="mailto:team@mimoveapp.com">team@mimoveapp.com</a>
            </div>
            <ContentBoxFooter>
              <FormErrorRenderer
                formErrors={errors}
                formErrorsKey={'non_field_errors'}
              />
              {hasFailed && (
                <FormErrorRenderer
                  formErrors={{ error: 'Failed to login' }}
                  formErrorsKey={'error'}
                />
              )}
              <GoogleLogin responseGoogle={this.responseGoogle}></GoogleLogin>
            </ContentBoxFooter>
          </>
        )}
        {(!this.state.is_teacher ||
          (this.state.is_teacher &&
            this.state.selectedSchool?.sso === 'NONE')) && (
          <>
            <FormGroup block>
              <label>Email Address</label>
              <Field
                name="username"
                component="input"
                type="email"
                disabled={isLoading}
              />
              <FormErrorRenderer
                formErrors={errors}
                formErrorsKey={'username'}
              />
            </FormGroup>
            <FormGroup block>
              <label>Password</label>
              <Field
                name="password"
                component="input"
                type="password"
                disabled={isLoading}
              />
              <FormErrorRenderer
                formErrors={errors}
                formErrorsKey={'password'}
              />
            </FormGroup>
            <hr />
            <div className="margin-vertical-base">
              Help: Having trouble logging in. Please email{' '}
              <a href="mailto:team@mimoveapp.com">team@mimoveapp.com</a>
            </div>
            <ContentBoxFooter>
              <FormErrorRenderer
                formErrors={errors}
                formErrorsKey={'non_field_errors'}
              />
              {hasFailed && (
                <FormErrorRenderer
                  formErrors={{ error: 'Failed to login' }}
                  formErrorsKey={'error'}
                />
              )}
              {isLoading ? (
                <span>Loading&hellip;</span>
              ) : (
                <Button type="submit" disabled={isLoading} className="pill">
                  Log in
                </Button>
              )}
            </ContentBoxFooter>
          </>
        )}
      </form>
    );
  }
}

export function mapStateToProps({ responses }: StoreState) {
  return {
    // isLoading: anyPending(responses, [LOGIN_SOCIAL]),
    hasLoginFailed: hasFailed(responses, LOGIN_SOCIAL),
    errors: getResponseErrors(responses, LOGIN_SOCIAL),
  };
}

const connected = connect(mapStateToProps, {
  getSchoolsList,
})(LoginForm);

export default reduxForm<LoginData, ExternalProps>({
  form: 'login',
})(connected);

interface GoogleLoginProps {
  responseGoogle: any;
}

function GoogleLogin(props: GoogleLoginProps) {
  const googleLogin = useGoogleLogin({
    onSuccess: (codeResponse: any) => props.responseGoogle(codeResponse),
    flow: 'implicit',
  });

  return (
    <>
      <Button type="button" className="pill" onClick={() => googleLogin()}>
        Sign In with Google
      </Button>
    </>
  );
}

interface MicrosoftLoginProps {
  responseMicrosoft: any;
}

function MicrosoftLogin(props: MicrosoftLoginProps) {
  const { instance } = useMsal();

  const microsoftLogin = (loginType: string) => {
    if (loginType === 'popup') {
      instance
        .loginPopup(loginRequest)
        .then((response) => {
          props.responseMicrosoft(null, response);
        })
        .catch((e) => {
          props.responseMicrosoft(e, null);
        });
    }
  };

  return (
    <>
      <Button
        type="button"
        className="pill"
        onClick={() => microsoftLogin('popup')}
      >
        Sign In with Microsoft
      </Button>
    </>
  );
}
